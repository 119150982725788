// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
img { 
    display: block; 
    vertical-align: middle;
} 

img { 
    max-width: 100%; 
    height: auto;
} 

.object-contain { 
    object-fit: contain;
} 

*,:after,:before { 
    box-sizing: border-box; 
    border: 0 solid #e5e7eb;
} 

*,:after,:before { 
    --tw-ring-offset-shadow: 0 0 #0000; 
    --tw-ring-shadow: 0 0 #0000; 
    --tw-shadow: 0 0 #0000; 
} 

:backdrop { 
    --tw-ring-offset-shadow: 0 0 #0000; 
    --tw-ring-shadow: 0 0 #0000; 
    --tw-shadow: 0 0 #0000; 
} 


.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    margin-bottom: 30px;
    
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Category/Category.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IAEI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;IAClC,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;IAClC,2BAA2B;IAC3B,sBAAsB;AAC1B;;;AAGA;IACI,mBAAmB;;AAEvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":["\r\nimg { \r\n    display: block; \r\n    vertical-align: middle;\r\n} \r\n\r\nimg { \r\n    max-width: 100%; \r\n    height: auto;\r\n} \r\n\r\n.object-contain { \r\n    -o-object-fit: contain; \r\n    object-fit: contain;\r\n} \r\n\r\n*,:after,:before { \r\n    box-sizing: border-box; \r\n    border: 0 solid #e5e7eb;\r\n} \r\n\r\n*,:after,:before { \r\n    --tw-ring-offset-shadow: 0 0 #0000; \r\n    --tw-ring-shadow: 0 0 #0000; \r\n    --tw-shadow: 0 0 #0000; \r\n} \r\n\r\n:backdrop { \r\n    --tw-ring-offset-shadow: 0 0 #0000; \r\n    --tw-ring-shadow: 0 0 #0000; \r\n    --tw-shadow: 0 0 #0000; \r\n} \r\n\r\n\r\n.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {\r\n    margin-bottom: 30px;\r\n    \r\n}\r\n.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{\r\n    margin-bottom: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
