import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DataService from '../../services/requestApi'
import ConceptMaster from './ConceptMaster';
import ItemMaster from './ItemMaster';
import Department from './Department';
import ClassMaster from './ClassMaster';
import SubClassMaster from './SubClassMaster';
import ProductUpload from './ProductUpload';
import UserMaster from './UserMaster';
import StoreMaster from './MangeAllModal/StoreMaster';
import CurrancyMaster from './MangeAllModal/CurrancyMaster';
import BossReport from './BossReport';
import CouponMaster from './CouponMaster';
const MageTab = ({concept,GetConcept}) => {
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [currentPage, setCurrentPage]= useState('1')
    const [size , setSize] = useState(10)
    const [Activetab, setActivetab] = useState('')
    const [loading, setLoading] = useState(true)
    //Get Report Api CAll
    const [count, setCount] = useState('')
    const [data, setData]= useState('')
  const GetItemReport=async()=>{
    try {
      setLoading(true)
      const response =await DataService.GetItemMaster(saasId,storeId,currentPage)
      console.log("Get Average Sales", response.data.data)
      setData(response.data.data)
      setCount(response.data.count)
      setLoading(false)
    } catch (error) {
      console.log("error", error)
    }
  
  }

  // all User mAster 
  const GetUser=async()=>{
    try {
      setLoading(true)
      const response =await DataService.GetAllUserMaster(currentPage,saasId)
      console.log("Get User", response.data.data)
      setTimeout(() => {
        setData(response.data.data)
        setCount(response.data.count)
        setLoading(false)
      }, 1000);
  
    } catch (error) {
      console.log("error", error)
    }
  
  }


  // get Store Master 
  const [store, setStore]= useState('')
  const GetStoreMaster=async()=>{
    try {
      
      const response =await DataService.GetAllStoreMaster(saasId,storeId)
      console.log("Get Average Sales", response.data.data)
      setStore(response.data.data)
      // setCount(response.data.count)
  
    } catch (error) {
      console.log("error", error)
    }
  
  }

  // currency Master 
    // get Store Master 
    const [currency, setCurrency]= useState('')
    const GetCurrencyMaster=async()=>{
      try {
        
        const response =await DataService.GetAllCurrencyMaster()
        console.log("Get Average Sales", response.data.data)
        setCurrency(response.data.data)
        // setCount(response.data.count)
    
      } catch (error) {
        console.log("error", error)
      }
    
    }
  //Api Call For Aub Class
  const [SubClass, setSubClsss]= useState('')
const GetSubReport=async()=>{
  try {
    setLoading(true)
    const response =await DataService.GetSubClass(currentPage,size)
    console.log("Get Average Sales", response.data.data)
    setSubClsss(response.data.data)
    setCount(response.data.count)
    setLoading(false)
  } catch (error) {
    console.log("error", error)
  }

}

//Department Tab Api Hit
const [Dept, setDept] = useState("")
  const GetDeptMaster=async()=>{
  try {
    const response = await DataService.DeptMaterGet()
    console.log("this dept", response.data)
    setDept(response.data.data)
  } catch (error) {
    console.log("this is catch ",error)
  }
  }

//Classs Tab Api Hit
const [allclass, setallClass] = useState("")
  const GetAllClass=async()=>{
  try {
    setLoading(true)
    const response = await DataService.GetAllClassMaster(currentPage,size )
    console.log("this dept", response.data)
    setCount(response.data.count)
    setallClass(response.data.data)
    setLoading(false)
  } catch (error) {
    console.log("this is catch ",error)
  }
  }
  


//Handel Pagination
const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
  if(Activetab=='Item Master'){
    GetItemReport()
  }else if(Activetab=='Sub Master'){
    GetSubReport()
  }else if(Activetab=='Department'){
    GetDeptMaster()
  }else if(Activetab=='Concept Master'){
    GetConcept()
  }
  else if(Activetab=='Class Master'){
    GetAllClass()
  }
  else if(Activetab=='User Master'){
    GetUser()
  }
  else if(Activetab=='Store Master'){
    
    GetStoreMaster()
  }
  else if(Activetab=='Currency Master'){
    
    GetCurrencyMaster()
  }
  }, [Activetab,currentPage,size])
  

  return (
    <div> <Tabs
    defaultActiveKey="Concept Master"
    id="uncontrolled-tab-example"
    className="mb-3  fw-bold"
    fill
    style={{background: '#FFF',
      boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
      }}
      onSelect={(key)=>{
        setActivetab(key)
        setCurrentPage('1')
      }}
  >
    <Tab eventKey="Concept Master" title="Concept">
      <ConceptMaster data={concept} GetConcept={GetConcept}/>
    </Tab>
    <Tab eventKey="Item Master" title="Item Master">
      <ItemMaster loading={loading} GetItemReport={GetItemReport} setData={setData} count={count} data={data} handlePageChange={handlePageChange} />
    </Tab>
    <Tab eventKey="Department" title="Department">
      <Department data={Dept} GetDeptMaster={GetDeptMaster}/>
    </Tab>
    <Tab eventKey="Class Master" title="Class">
      <ClassMaster loading={loading} count={count} size={size} setSize={setSize} handlePageChange={handlePageChange} GetAllClass={GetAllClass} allclass={allclass} />
    </Tab>
    <Tab eventKey="Sub Master" title="Sub Class">
      <SubClassMaster loading={loading} count={count} size={size} setSize={setSize} handlePageChange={handlePageChange} SubClass={SubClass} GetSubReport={GetSubReport}/>
    </Tab>
    {/* <Tab eventKey="Sales Man" title="Sales Man">
    <ManageSalesman/>
    </Tab> */}
    
    <Tab eventKey="User Master" title="User Master">
      <UserMaster loading={loading} GetUser={GetUser} setData={setData} count={count} data={data} handlePageChange={handlePageChange}/>
    </Tab>
    <Tab eventKey="Store Master" title="Store Master">
      <StoreMaster data={store}  />
    </Tab>
    <Tab eventKey="Currency Master" title="Currency Master">
      <CurrancyMaster data={currency} GetCurrencyMaster={GetCurrencyMaster}/>
    </Tab>
    <Tab eventKey="ProductUplaod" title="Mass Upload">
      <ProductUpload tab={Activetab}/>
    </Tab>
    <Tab eventKey="Boss" title="Boss Report">
      <BossReport/>
    </Tab>
    <Tab eventKey="Coupon" title="Coupon Master">
      <CouponMaster/>
    </Tab>
  </Tabs></div>
  )
}

export default MageTab