import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { BASEURL } from '../services/http-common';
import Swal from 'sweetalert2';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DataService from '.././services/requestApi'
function AddCustomerModal(props) {
  const {show, GetCustomertolink, isHome}= props
  
    const API_URL = `${BASEURL.ENDPOINT_URL}/customer/add-customer-vast-retail`;
    const { saasId, storeId, } = JSON.parse(localStorage.getItem("User_data"))
    const { tnc} = JSON.parse(localStorage.getItem("Store_data"));
    // const Input = useRef()
    const ManagerFocus = useRef(null);
    const [Userid, setUserid] = useState("");
    const [password, setPassword] = useState("");
    const [pricemodal, setPricemoadl] = useState(false);
    const [Salesmanid, setSalsemanId] = useState("")
    const [formData, setFormData] = useState({

      mobile_number: '',
      email: '',
      name: '',
      address_1: '',
      address_2: '',
      address_3: '',
      source_of_acq: '',
      dob: '',
      gender: '',
      occupation: '',
      income_level: 0,
      discount_percent: 0,
      customer_type: '',
      saas_id: saasId,
      store_id: storeId,
      sub_centre_id: 0,
      card_number: '',
    });
    const GetSalesmanId =async()=>{
  try {
    if (!formData.address_3) throw Error
    const response =  await DataService.GetSalesmane(saasId,storeId,formData.address_3)
    console.log(response)
    if(response.data.status){
      setSalsemanId(response.data.data)
      Swal.fire({
        title:`Selected Staff Name is ${response.data.data.sales_man_name}`,
        icon:"success",
        timer:2000
      })
    }else{
      Swal.fire({
        title:"Please Enter Right Staff Id",
        icon:"error",
        timer:2000
      })
    }

  } catch (error) {
    console.log(error)
    Swal.fire({
      title:"Please Enter Right Staff Id",
      icon:"error",
      timer:2000
    })
  }
    }
    // useEffect(() => {
    //   if(show){


    //     setTimeout(() => {
    //       console.log("first")
    //       Input.current.focus()
    //     }, 1000);
    //   }
    //   console.log(Salesmanid)
    // }, [show])
    
    // useEffect(() => {
    //   GetSalesmanId()
    // }, [formData.address_3])
    
   
    
      const handleChange = (e) => {
        console.log(e.target.value)
        if (e.target.name == "discount_percent")  {
          if(e.target.value>100)
          e.target.value=100;
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        }else if(e.target.name == "card_number"){
          let v = e.target.value
          if (isNaN(v)) {
           return 
          }else{
            setFormData({
              ...formData,
              [e.target.name]: e.target.value,
            });
          }
        }
        else{
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        }
      };
    
      const handleSubmit = async () => {
        try {
          if(tnc == "False"){
          if(formData?.discount_percent>20 && pricemodal==false){
            setPricemoadl(true)
            setTimeout(() => {
              ManagerFocus.current.focus()
            }, 500);
            return 
          }
        }
        if(!formData?.saas_id && !formData?.store_id){
          formData.saas_id = saasId
          formData.store_id = storeId
        }
          const response = await axios.post(API_URL, formData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if(response.data.status){
            console.log('API Response:', response.data);
             props.onHide()
             setFormData("")
             if(!isHome){
               GetCustomertolink()
             }
             Swal.fire({
              title:"Added Successfully",
              icon:"success",
              timer:1500
            })
          }else{
            Swal.fire({
              title:response.data.message,
              icon:"error",
              timer:1500
            })
          }
        } catch (error) {
          Swal.fire({
            title:error?.response?.data?.message,
            icon:"error",
            timer:1500
          })
          console.error('Error making API call:', error);
        }
      };
      const CheckManger = async () => {
        try {
          const response = await DataService.CheckManger(Userid, password);
          console.log("check manger", response);
          if (response.data.status) {
            if(response.data.data.user_type == "MANAGER"){
              handleSubmit()
              setPricemoadl(false) 
            }
    
          }else{
            Swal.fire({
              title:"You are Not Allow",
              icon:"error",
              timer:1500
            })
          }
    
        } catch (error) {
          console.log("Catch Error", error);
        }
      };
  return (
    <>
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
                    <Modal.Body>
                    <Modal.Header closeButton>
        <Modal.Title>
        Add Customer
        </Modal.Title>
      </Modal.Header>

                    <div className='container mt-3'>
                    <Grid container spacing={2}>
                    <Grid item xs={4}>
                    <Form.Control
                    autoFocus={true}
                    maxLength={11}
                    type='text'
                    className='h-100'
                    placeholder='Card Number'
        name="card_number"
        value={formData.card_number}
        onChange={handleChange}
        fullWidth
      ></Form.Control></Grid>
      
      <Grid item xs={4}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
      <FormControl className='h-100' fullWidth>
        <Form.Select className='h-100' name='gender' aria-label="Default select example"
        value={formData.gender}
        onChange={handleChange}
        >
      <option>Select gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </Form.Select>
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name='gender'
          value={formData.gender}
          onChange={handleChange}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Male</MenuItem>
        </Select> */}
      </FormControl>
       </Grid>
      <Grid item xs={4}>
      <TextField
          id="outlined-number"
          label="Mobile Number"
          type="number"
          name="mobile_number"
          value={formData.mobile_number}
          onChange={handleChange}
          fullWidth
        />
        
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
        
      <Grid item xs={4}>
      {/* <InputLabel id="demo-simple-select-label">Date Of Birth</InputLabel> */}
      <Form.Control
                    type='date'
                    className='h-100'
                    placeholder="Date of Birth"
                    name="dob"
        value={formData.dob}
          onChange={handleChange}
          fullWidth
      ></Form.Control>
        {/* <TextField
          label="Date of Birth"
          name="dob"
          type='date'
          value={formData.dob}
          onChange={handleChange}
          fullWidth
        /> */}
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="City"
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Area Name"
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <Form onSubmit={(e)=>{
          e.preventDefault();
          GetSalesmanId()
        }}>
        <TextField
          label="Staff Id"
          name="address_3"
          value={formData.address_3}
          onChange={handleChange}
          fullWidth
        />
        </Form>
       {/* <input list="browsers" />
    <datalist id="browsers">
        {Salesmanid && Salesmanid.map((item, index) => (
            // <option key={index} value={item.sales_man_id}>
            //     {item.sales_man_id}
            // </option>
            <option key={index} value="1001">
                1001
            </option>
        ))}
    </datalist> */}
      </Grid>
      {/* <Grid item xs={4}>
        <TextField
          label="Source of Acquisition"
          name="source_of_acq"
          value={formData.source_of_acq}
          onChange={handleChange}
          fullWidth
        />
      </Grid> */}
      
      
       {/* <Grid item xs={4}>
      <TextField
        label="Occupation"
        name="occupation"
        value={formData.occupation}
        onChange={handleChange}
        fullWidth
      /></Grid>  */}
      {/* <Grid item xs={4}>
      <TextField
        label="Income Level"
        name="income_level"
        type="number"
        value={formData.income_level}
        onChange={handleChange}
        fullWidth
      /></Grid>  */}
      
       
       <Grid item xs={4}>
       <FormControl className='h-100' fullWidth>
        <Form.Select className='h-100' name='customer_type' aria-label="Default select example"
        value={formData.customer_type}
        onChange={handleChange}
        >
      <option value="">Select Customer Type</option>
      <option value="Customer">Customer</option>
      <option value="Staff">Staff</option>
      <option value="Loyality">Loyality</option>
    </Form.Select>
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name='customer_type'
          value={formData.customer_type}
          onChange={handleChange}
        >
          <MenuItem value="Customer">Customer</MenuItem>
          <MenuItem value="Employe">Employe</MenuItem>
          <MenuItem value="Loyality">Loyality</MenuItem>
        </Select> */}
      </FormControl>
      </Grid> 
      <Grid item xs={4}>
      <TextField
        label="Discount Percent"
        name="discount_percent"
        type="number"
        max={100}
        value={formData.discount_percent}
        onChange={handleChange}
        fullWidth
        />
        {formData.discount_percent>20&&<span className='text-danger'>Above 20% Discount Not Allowd</span>}
        </Grid>
       {/* <Grid item xs={4}>
      <TextField
        label="Sub Centre ID"
        name="sub_centre_id"
        type="number"
        value={formData.sub_centre_id}
        onChange={handleChange}
        fullWidth
      /></Grid>  */}
     
      
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
          Submit
        </Button>
      </Grid>
    </Grid>
    </div>


                 </Modal.Body>
    </Modal>
      {/* <----------------PRICE OVERRIDE MODAL--------------------> */}
      <Modal show={pricemodal} onHide={() => {setPricemoadl(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>Check Manger</Modal.Title>
      </Modal.Header>
          <Modal.Body>
            <Form>
              <Row style={{ margin: "40px 0px 0px -10px" }}>
                <Col>
                  <Form.Control
                    type="text"
                    ref={ManagerFocus}
                    onChange={(e) => setUserid(e.target.value)}
                    style={{ width: "88%" }}
                    placeholder="Enter User Id"
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: "88%" }}
                    placeholder="Enter Password"
                  ></Form.Control>
                </Col>
              </Row>

              {/* <Form.Label>Discount(%)</Form.Label>
                <Form.Control style={{width:"88%"}} placeholder="0"></Form.Control> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPricemoadl(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={CheckManger}>
              Save Changes
            </Button>
          </Modal.Footer>
      
    </Modal>
    </>
  );
}

export default AddCustomerModal;
