// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 990px) {
    .sale-purchase-container {
        display: flex;
        justify-content: space-between;
    }

}

@media only screen and (min-width:1400px) {
    .lkksi3 {
        display: flex;
        justify-content: flex-start;
    }
}

@media only screen and (max-width:1400px) {
    .lkksi3 {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/retailer-dashboard/inventory-dashboard/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,8BAA8B;IAClC;;AAEJ;;AAEA;IACI;QACI,aAAa;QACb,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,aAAa;QACb,2BAA2B;QAC3B,eAAe;IACnB;AACJ","sourcesContent":["@media only screen and (min-width: 990px) {\n    .sale-purchase-container {\n        display: flex;\n        justify-content: space-between;\n    }\n\n}\n\n@media only screen and (min-width:1400px) {\n    .lkksi3 {\n        display: flex;\n        justify-content: flex-start;\n    }\n}\n\n@media only screen and (max-width:1400px) {\n    .lkksi3 {\n        display: flex;\n        justify-content: flex-start;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
