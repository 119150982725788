import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import SalesModal from "./StoreinsightsModals/SalesModal";
import DataService from "../services/requestApi";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import ReceiptModal from "../UiComponents/ReaciptModal";
import { AiFillPrinter, AiOutlineClose } from "react-icons/ai";
import { Box } from "@mui/material";
import RePrintModalShow from "./StoreinsightsModals/RePrintModalShow";
import { useReactToPrint } from "react-to-print";
import { IoMdClose } from "react-icons/io";
import ButtonForPrintscreen from "./StoreinsightsModals/ButtonForPrintscreen";
import sales from "../Images/sales.jpg"
const SalesSeaction = () => {
  const [modalShow, setModalShow] = useState(false);
  const componentRef = useRef();
  const [showPdf,setShowPdf] =  useState(false);
  const [InvoiceToPrint, setInvoiceToPrint] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [Avergesale, setAgvergsales] = useState("");
  const [Activetab, setActivetab] = useState("");
  const { saasId, storeId,userType } = JSON.parse(localStorage.getItem("User_data"));
  const today = moment(new Date()).format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      console.log("printing End");
    },
  });
  const GetAverageSales = async () => {
    try {
      console.log("Active Tab:", Activetab);
      let response;
      if (Activetab === "Average") {
        response = await DataService.AverageSales(saasId, storeId, date);
      } else if (Activetab === "ConceptDeptReport") {
        response = await DataService.ConceptDeptReport(saasId, storeId, date);
      } else if (Activetab === "SalesSummary") {
        response = await DataService.getSalesSummary(
          saasId, storeId, startDate, endDate
        );
      } else if (Activetab === "DailySales") {
        response = await DataService.getDailySales(
          startDate, storeId, saasId
        );
      }

      if (response) {
        console.log("API Response:", response.data.data || response.data.list_sales_report);
        setAgvergsales(response.data.data || response.data.list_sales_report);
        setModalShow(true);
      }
    } catch (error) {
      console.log("Error in GetAverageSales function", error);
    }
  };

  useEffect(() => {
    console.log("date:", date);
    if (Activetab || date || startDate || endDate) {
      GetAverageSales();
    }
  }, [Activetab, date, startDate, endDate]); // Consolidated dependency array

  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  const Modalstyle = {
    overflowY: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: 635,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
  };
  const header = {
    container: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
    text: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
  };
  
 //Api Call For Show Invoice detail by invoice number
 const showInvoiceDetail = async (invoice_number) => {
  try {
    const response = await DataService.getInvoiceDetail( invoice_number);
    if (response) {
      console.log("API Response:", response.data);
      setInvoiceToPrint(response.data.data);
      setShowPdf(true);
      setModalShow(false)
      setActivetab("")
    }
  } catch (error) {
    console.log("Error in showInvoiceDetail function", error);
  }
}
  // console.log("selecion component", Avergesale);

  const columns = [
    {
      name: `${
       Activetab == "DailySales"?"Type":""
      }`,
      selector: (row) =>
        Activetab == "DailySales"?row.type:"",
      sortable: true,
      width:"90px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "No of Invoice"
          : Activetab == "ConceptDeptReport"
          ? "Concept Number"
          : Activetab == "SalesSummary"
          ? "Business Date"
          : "Business Date"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_invoices
          : Activetab == "ConceptDeptReport"
          ? row.conc_id
          : Activetab == "SalesSummary"
          ? row.business_date
          : row.business_date,
      sortable: true,
      width:"150px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "Sales Total"
          : Activetab == "ConceptDeptReport"
          ? " Conecpt Name "
          : Activetab == "SalesSummary"
          ? "Gross Sales"
          : "Invoice Number"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_item_net
          : Activetab == "ConceptDeptReport"
          ? row.conc_name
          : Activetab == "SalesSummary"
          ? row.invoice_total
          : row.invoice_no,
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "Total Quantity"
          : Activetab == "ConceptDeptReport"
          ? " Dept Number   "
          : Activetab == "SalesSummary"
          ? "Discount Total"
          : "Gross Total"
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.total_item_qty?.toFixed(0)
          : Activetab == "ConceptDeptReport"
          ? row.dept
          : Activetab == "SalesSummary"
          ? row.discount_total
          : row.invoice_total,
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "SalesSummary"?"Return":""
      }`,
      selector: (row) =>
        Activetab == "SalesSummary"?row.return_net_value:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "ATV "
          : Activetab == "ConceptDeptReport"
          ? "Dept Name "
          : Activetab == "DailySales"
          ? "Discount"
         :Activetab == "SalesSummary" ? "Net Sales"  :""
      }`,
      selector: (row) =>
        Activetab == "Average"
          ? row.atv?.toFixed(0)
          : Activetab == "ConceptDeptReport"
          ? row.dept_name
          : Activetab == "DailySales"
          ? row.discount
          : Activetab == "SalesSummary" ? row.net_value - row.return_net_value :"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "DailySales"?"Quantity":""
      }`,
      selector: (row) =>
        Activetab == "DailySales"?row.qty:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
        Activetab == "Average"
          ? "UPT "
          :     Activetab == "DailySales"
          ? "Net Total"
         :Activetab == "ConceptDeptReport"?"Store Id  ":""
          
      }`,
      selector: (row) =>
      Activetab == "Average"
      ? row.upt?.toFixed(0)
      :    Activetab == "DailySales"
      ? row.netValue
      : Activetab == "ConceptDeptReport"?row.store_id:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${ userType =="CASHIER"?"":Activetab =="DailySales"?"Action":""}`,
      center: true,
      selector: (row) => (
        <>
        <div className="d-flex">
          <div onClick={()=>showInvoiceDetail(row.invoice_no)} className="me-2" style={{cursor:'pointer'}}>
            <FaEye 
              size={22}
              color="green"
              className="mouse-pointer" 
            />
          </div>
          
          
        </div>
        
       </>
      ),
    },

    {
      name: `${
       Activetab == "ConceptDeptReport"?" Total Gross Sales ":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_value:"",
      sortable: true,
      width:"200px"
    },
    {
      name: `${
       Activetab == "ConceptDeptReport"?"Discount":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_discount:"",
      sortable: true,
      width:"200px"
    },
    
    
    {
      name: `${
       Activetab == "ConceptDeptReport"?"Net Sales":""
      }`,
      selector: (row) =>
        Activetab == "ConceptDeptReport"?row.total_item_net:"",
      sortable: true,
      width:"200px"
    },
  ];
  // useEffect(() => {
  //   if (Activetab) {
  //     GetAverageSales();
  //   }
  //   if (startDate) {
  //     GetAverageSales();
  //   }
  //   if (startDate || endDate) {
  //     GetAverageSales();
  //   }
  // }, [Activetab, startDate, endDate]);
// Css or buttons 


  return (
    <>
    <Container>
      <Row>
        <Col>
          <div>
            <img
              className="w-100 rounded-4"
              style={{
                background: "lightgray 50% / cover no-repeat",
                boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
              }}
              src={sales}
              alt=""
            />
          </div>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setActivetab("Average");
            }}
            style={style}
            className="w-100"
            variant="success"
            id="dropdown-basic"
          >
            Average Sales
          </Button>

          <Button
            onClick={() => {
              setActivetab("DailySales");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Daily Sales
          </Button>

          <Button
            onClick={() => {
              setActivetab("SalesSummary");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Sales Summary
          </Button>

          <Button
            onClick={() => {
              setActivetab("ConceptDeptReport");
            }}
            style={style}
            className="w-100 mt-3"
            variant="success"
            id="dropdown-basic"
          >
            Concept/Dept. Sales
          </Button>
        </Col>
      </Row>
      <SalesModal
        show={modalShow}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setActivetab={setActivetab}
        Activetab={Activetab}
        setModalShow={setModalShow}
        columns={columns}
        data={Avergesale}
        setDate={setDate}
        date={date}
      />
       
       
    </Container>
    {/* <Modal
    open={showPdf}
    // onClose={}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  > */}
    {showPdf &&<Box sx={Modalstyle}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          cursor: "pointer",
        }}
        // onClick={() => {
        //   SetShowPdf((state) => !state);
        //   dispatch(RemoveCart());
        //   navigate("/");
        // }}
      >
        <AiOutlineClose onClick={()=>{setShowPdf(false)}} size={20} />
      </div>

      <div
        className="container"
        style={header.container}
        ref={componentRef}
      >
        {/* <div style={{ height: "550px", overflowY: "auto" }}> */}
        <RePrintModalShow
        invoice_header = {InvoiceToPrint.invoice_header}
        invoice_detail = {InvoiceToPrint.invoice_detail}
        payment_details = {InvoiceToPrint.payment_details}
        />

        {/* </div> */}
      </div>

      {/* <ModalFooter> */}
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }} 
        > */}
      <ButtonForPrintscreen handlePrint={handlePrint} setShowPdf={setShowPdf} />
        {/* </div> */}
      {/* </ModalFooter> */}

     
    </Box>}
  {/* </Modal> */}
  </>
  );
};
export default SalesSeaction;
