// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container-style {
    /* background-color: var(--primary1) !important; */
    background-color: #ffffff;
    color: black;
    margin-bottom: 20px;
    /* width: 100% !important; */
    /* color: white !important; */
    border: none !important;
    border-radius: 10px !important;
    padding: 15px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/retailer-dashboard/sales-dashboard/index.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,6BAA6B;IAC7B,uBAAuB;IACvB,8BAA8B;IAC9B,kBAAkB;AACtB","sourcesContent":[".card-container-style {\n    /* background-color: var(--primary1) !important; */\n    background-color: #ffffff;\n    color: black;\n    margin-bottom: 20px;\n    /* width: 100% !important; */\n    /* color: white !important; */\n    border: none !important;\n    border-radius: 10px !important;\n    padding: 15px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
