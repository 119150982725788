import React, { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Export from "react-data-table-component";
import { Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { Input } from "reactstrap";
import moment from "moment";
import { CSVLink } from "react-csv";
function SalesModal({
  data,
  show,
  setModalShow,
  columns,
  Activetab,
  setActivetab,
  setDate,
  endDate,
  setStartDate,
  startDate,
  setEndDate,
  date,
}) {
  const tableData = {
    columns,
    data,
  };
    console.log("avergsale", data)
  const downloadCSV = (data) => {
    // Check if data is not null and has at least one row
    if (data && data.length > 0) {
      const csvData = data
        .map((row) => Object.values(row).join(","))
        .join("\n");
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download",  `${Activetab == "Average"?"AverageSales.csv":Activetab =="DailySales"?"DailySales.csv": Activetab =="SalesSummary"?"SalesSummary.csv":"ConceptDeptReport.csv"}`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      // Provide feedback or handle the case where there is no data to download
      Swal.fire({
        title: "No data available for download.",
        icon: "info",
        timer: 1500,
      });
      // You can also show a message to the user if needed.
    }
  };
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );

  // const handleDateChange = (e) => {
  //   const inputDate = e.target.value;
  //   const formattedDate = moment(inputDate).format("YYYY-MM-DD");
  //   setDate(formattedDate);
  //   console.log(date);
  // };
  const filteredColumns = columns.filter(column => column.name !== "");
  const headers = [
    { label: "Business Date", key: "business_date" },
    { label: "Gross Sales", key: "invoice_total" },
    { label: "Discount Total", key: "discount_total" },
    { label: "Return", key: "return_net_value" },
    { label: "Net Sales", key: "net_value" }
];
  
const dailySalesHeaders = [
  { label: "Type", key: "type" },
  { label: "Business Date", key: "business_date" },
  { label: "Invoice Number        ", key: "invoice_no" },
  { label: "Gross Total", key: "invoice_total" },
  { label: "Discount", key: "discount" },
  { label: "Quantity", key: "qty" },
  { label: "Net Total", key: "netValue" },
]
  const rawData =data&& data?.map(item => ({
    ...item,
    net_value: item.net_value - item.return_net_value
  }));
  const Dailysales =data && data?.map(item => ({
    ...item,
    invoice_no:`'${item?.invoice_no?.toString()}` 
  }));
  const footerValues = useMemo(() => {
    console.log("this Footer", Activetab, data);

    const initialSums = {
      total_invoices: 0,
      invoice_total: 0,
      total_item_net: 0,
      total_item_qty: 0,
      discount_total: 0,
      return_net_value: 0,
      net_value: 0,
      qty: 0,
      upt: 0,
      total_AverageSales: 0,
      store_id: 0,
      total_item_value: 0,
      total_item_discount: 0,
      TotalConecptDiscount: 0,
    };

    const filteredData = Activetab === "DailySales" 
      ?data&& data?.filter((el) => el.type === 'Sale') 
      : data;

    return filteredData&&filteredData?.reduce((totals, row) => {
      totals.total_invoices += row.total_invoices || 0;
      totals.invoice_total += row.invoice_total || 0;
      totals.total_item_net += row.total_item_net || 0;
      totals.total_item_qty += row.total_item_qty || 0;
      totals.discount_total += row.discount_total || 0;
      totals.return_net_value += row.return_net_value || 0;
      totals.net_value += row.netValue || 0;
      totals.qty += row.qty || 0;
      totals.upt += row.upt || 0;
      totals.total_item_value += row.total_item_value || 0;
      totals.total_item_discount += row.discount || 0;
      totals.total_AverageSales += row.total_item_net || 0;
      totals.TotalConecptDiscount += row.total_item_discount || 0;
      // totals.total_ConceptSale += row.total_item_value || 0;
      return totals;
    }, initialSums);
  }, [Activetab, data]);
  console.log(footerValues.total_item_value)
  return (
    <Modal
      show={show}
      fullscreen={true}
      onHide={() => {
        setActivetab("");
        setModalShow(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Activetab === "Average"
            ? "Average"
            : Activetab === " ConceptDeptReport"
            ? " Concept Sales"
            : Activetab === "SalesSummary"
            ? "  Sales Summary"
            : Activetab === "DailySales"
            ? "Daily Sales"
            : "ConceptDeptReport"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Button onClick={() => downloadCSV(data)}>Export</Button> */}
        <CSVLink
        className="text-decoration-none bg-primary text-white p-2 rounded"
         filename={`${Activetab}.csv`} 
        data={Activetab === "DailySales"?Dailysales:rawData} headers={Activetab === "DailySales"?dailySalesHeaders :headers}>
  Download CSV
</CSVLink>
        <br />
        {(Activetab === "Average" || Activetab === "ConceptDeptReport") && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ccc",
              paddingBottom: "5px",
            }}
          >
            <input
              type="date"
              onChange={(e)=>{
                const inputDate = e.target.value;
                const formattedDate = moment(inputDate).format("YYYY-MM-DD");
                setDate(formattedDate);
              }}
              style={{
                appearance: "none",
                WebkitAppearance: "none",
                MozAppearance: "none",
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                outline: "none",
                width: "500px",
                marginTop: "1rem",
              }}
            />
            <h3 className="mx-5">
              {Activetab} of {date}
            </h3>
          </div>
        )}

        <div className="d-flex  mt-3 mb-3 ">
          <div className="w-25 mx-2 fw-bold">
          {Activetab === "SalesSummary" ?  ( <span>  Start Date{" "}</span>    ) : null}
            {Activetab === "DailySales" || Activetab === "SalesSummary" ? (
              <Form.Control
                type="date"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setEndDate(e.target.value);
                }}
              />
            ) : null}{" "}
          </div>
          <div className="w-25 fw-bold">
            {" "}
            {/* {Activetab === "SalesSummary" ? ( <span> End Date{" "} </span>    ) : null}
            {Activetab === "SalesSummary" ? (
              <Form.Control
                type="date"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                value={endDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setEndDate(e.target.value);
                }}
              />
            ) : null} */}
          </div>
        </div>
        {/* <Export onExport={() => downloadCSV(data)} /> */}
        <div style={{height:"400px", }}>

        <DataTable
         actions={actionsMemo}
         noHeader
         defaultSortField="id"
         defaultSortAsc={false}
         columns={filteredColumns}
         data={data}
        //  pagination
        //  paginationServer
         responsive
         fixedHeader
         fixedHeaderScrollHeight="400px"
          />
          </div>
         {/* Custom footer row */}
      { Activetab === "DailySales" || Activetab === "Average" || Activetab === "ConceptDeptReport" ?<div style={{ display: 'flex', padding: '10px', background: '#f0f0f0', borderTop: '1px solid #ddd', justifyContent:"space-between" }}>
        <div style={{ width: '90px' }}>{/* Column-specific labels */}</div>
        {Activetab === "Average" && <div style={{ width: '150px' }}>Total Sales: {footerValues.total_AverageSales }</div>}
        {Activetab === "Average" && <div style={{ width: '150px' }}>Total no Invoice: {footerValues.total_invoices }</div>}
      { Activetab === "Average" &&<div style={{ width: '200px' }}>Total Qty: { footerValues.total_item_qty }</div>}
     {/* { Activetab === "SalesSummary" &&<div style={{ width: '200px' }}>{ footerValues.discount_total }</div>} */}
     {/* {Activetab === "SalesSummary" &&<div style={{ width: '200px' }}>{ footerValues.net_value }</div>} */}
       { Activetab === "DailySales" &&<div style={{ width: '200px' }}>Total Sales Gross: { footerValues.invoice_total }</div>}
     {Activetab === "DailySales" &&<div style={{ width: '200px' }}>Total Discount: { footerValues.total_item_discount }</div>}
     {Activetab === "DailySales" &&<div style={{ width: '200px' }}>Total Sales Qty: { footerValues.qty }</div>}
        {Activetab === "DailySales" &&<div style={{ width: '200px' }}>Total Of Net Sales:{ footerValues.net_value}</div>}
        {Activetab === "Average" &&<div style={{ width: '200px' }}>Total Upt: { footerValues.upt?.toFixed(2) }</div>}
        {/* <div style={{ width: '200px' }}>{Activetab === "ConceptDeptReport" ? footerValues.store_id : ""}</div> */}
        {/* <div style={{ width: '200px' }}>{Activetab === "ConceptDeptReport" ? footerValues.total_item_value : ""}</div> */}
        {Activetab === "ConceptDeptReport" &&<div style={{ width: '200px' }}>Total Concept Gross: { footerValues.total_item_value }</div>}
     {Activetab === "ConceptDeptReport" &&<div style={{ width: '200px' }}>Total Discount: { footerValues.TotalConecptDiscount }</div>}
        {Activetab === "ConceptDeptReport" &&<div style={{ width: '200px' }}>Total Net Sales: {footerValues.total_item_net }</div>}
      </div>:""}
      </Modal.Body>
    </Modal>
  );
}

export default SalesModal;
