import moment from 'moment';
import React from 'react';
import Barcode from 'react-barcode';
import { PrintProvider, Print } from 'react-easy-print';
import { useSelector } from 'react-redux';

function ReturnRiceaptModal({TotalAmount,barcodeData,Tender}) {
    const SelectedReturn = useSelector((state) => state.Reducer.SelectedReturn);
  const totalProductQty = SelectedReturn.reduce((total, item) => total + parseInt(item.productQty), 0);
  const { storeName } = JSON.parse(localStorage.getItem("User_data"))
  const { haderLine1,haderLine2,haderLine3,haderLine4,futterLine1,futterLine2,futterLine3,futterLine4,futterLine5,futterLine6,futterLine7  } = JSON.parse(localStorage.getItem("Store_data"))
  console.log(" SelectedReturn", SelectedReturn)
  const styles = {
    fontFamily: 'Verdana',
    fontSize: 'medium',
    fontWeight: 'bold',
  };

  const customLineStyle = {
    border: '1px solid black',
    margin: '8px 0',
  };
  const table = {
    border: '1px solid black',
    margin: '8px 0',
  };
  const receiptContent = (
    <div style={styles}>
      <p style={{fontSize: "1.6rem"}} className='d-flex  justify-content-around fw-bold'>{storeName}</p>
      <p style={{fontSize: "1.4rem"}} className='d-flex  justify-content-around fw-bold'>{haderLine1}</p>
      <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>{haderLine2}</p>
        <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>{haderLine4}</p>
      <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>Cash Refund</p>

      <div className='fw-bold mt-5' style={customLineStyle}></div>
      <table className='w-100  mx-md-auto' style={{fontSize: "1.2rem"}}>
        <thead>
        <tr className='border-dark' style={{ borderBottomWidth: '2px' }}>
            <th>Item</th>
            <th  className='px-3'>Qty</th>
            <th className='px-3'>Rate</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {SelectedReturn && SelectedReturn.map((el)=>{return(
          <><tr className='fw-bold'>
          <td>{el?.item_id}</td>
          <td>{el.productQty}</td>
          <td>{el.price}</td>
          <td>{el.item_net}</td>
        </tr>
        <tr className='fw-bold'>
          <td colspan="3" className='m-0' style={{fontSize:"1rem"}}>{el.item_name.slice(0, 25)}</td>
         {el.discount >0&& <td>{el.discount}%</td>}
        </tr></>
          )})}
          
        </tbody>
      </table>

      <div className='fw-bold' style={customLineStyle}></div>

      <div className='container' style={{fontSize: "1.25rem"}}>
        <div className="row  fw-bold">
          <div className="col">
            <p>Qty</p>
          </div>
          <div className="col">
            <p>{totalProductQty}</p>
          </div>
          <div className="col">
            <p>Gross</p>
          </div>
          <div className="col">
            <p>{TotalAmount}</p>
          </div>
        </div>
      </div>

      <div className='container mb-1 mt-1' style={{fontSize: "1.2rem"}}>
        <p style={{fontSize: "1.2rem"}}>Settlement</p>
        {Tender && Tender.map((item )=>{return(
         <div className="row fw-bold">
         <div className="col text-nowrap">
           <p>{item.tender_name}</p>
         </div>
      
         <div className="col">
           <p>INR</p>
         </div>
         <div className="col">
           <p>{item.amount}</p>
         </div>
       </div>
        )})}
      </div>

      <div className='fw-bold' style={customLineStyle}></div>
      
      <div className='container' style={{fontSize: "1.2rem"}}>
        <div className="col">
          {/* {ChangeDue && <div className="col p-2 fw-bold">
            <h5 className='fw-bold'>Change Due: {ChangeDue}</h5>
          </div>}  */}
          <div className="col p-2 fw-bold">
            <h5 className='fw-bold'>Net Settlement: {TotalAmount}</h5>
          </div> 
          {/* <div className="col p-2 fw-bold">
            <p>{TotalAmount}</p>
          </div> */}
        </div>
      </div>

      <div className='container' style={{fontSize: "1.1rem"}}>
        <div className="row">
       
          
            <p className='text-nowrap text-center'>Date: {moment(Date.now()).format('DD/MM/YYYY,h:mm:ss a')}</p>
            <div className='d-flex justify-content-center'  >
               <Barcode  value={barcodeData}  width={2} height={50}/>
              </div>
        </div>
      </div>

      <div className='fw-bold' style={customLineStyle}></div>

      <div className='container py-2 fw-bold' style={{fontSize: "1.1rem"}}>
        <div className="row">
        <div className='container' style={{fontSize: "1.2rem"}}>
        <div className="col">
        
          <div className="col p-2 fw-bold">
            <p className='fw-bold'>Authorised by : </p>

         <p className='fw-bold'>Accounts   :  </p>

          </div> 
        
        </div>
      </div>
        </div>
      </div>
    </div>
  );

  return (
     <PrintProvider>
      <Print 
      >
       <div style={styles}>
            {receiptContent}
          </div>

        
      </Print>
    </PrintProvider>
  );
}

export default ReturnRiceaptModal;







