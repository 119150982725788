// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width:770px) {
    .navbar-text {
        color: white;
        font-weight: bold;
        margin: auto;
        font-size: 40px;
    }
}

@media only screen and (max-width:770px) {
    .navbar-text {
        color: white;
        font-weight: bold;
        margin: auto;
        font-size: 30px;
    }
}

@media only screen and (max-width:400px) {
    .navbar-text {
        color: white;
        font-weight: bold;
        margin: auto;
        font-size: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/retailer-dashboard/navbar/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;QACZ,iBAAiB;QACjB,YAAY;QACZ,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,iBAAiB;QACjB,YAAY;QACZ,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,iBAAiB;QACjB,YAAY;QACZ,eAAe;IACnB;AACJ","sourcesContent":["@media only screen and (min-width:770px) {\n    .navbar-text {\n        color: white;\n        font-weight: bold;\n        margin: auto;\n        font-size: 40px;\n    }\n}\n\n@media only screen and (max-width:770px) {\n    .navbar-text {\n        color: white;\n        font-weight: bold;\n        margin: auto;\n        font-size: 30px;\n    }\n}\n\n@media only screen and (max-width:400px) {\n    .navbar-text {\n        color: white;\n        font-weight: bold;\n        margin: auto;\n        font-size: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
