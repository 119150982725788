import React, { useEffect, useState } from "react";
import { Login } from "./Login";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WelcomeSearch } from "./WelcomeSearch";
import { Scan } from "./Scan";
import { Cart } from "./Cart";
import { Sixth } from "./sixth";
import { Seventh } from "./Seventh";
import Rohini from "./Rohini";
import { Enrollment } from "./Enrollment";
import { Accountverify } from "./Accounterify";
import { Enrollform } from "./Enrollform";
import { Startoftheday } from "./startOfTheDay";
import { Tillopen } from "./Tillopen";
import { Tillclose } from "./Tillclose";
import { Tilloptions } from "./Tilloptions";
import { Tillreconcile } from "./Tillreconcile";
import { Tillreconcile2 } from "./Tillreconcile2";
import { Tillreconcile3 } from "./Tillreconcile3";
import { Tillreconcile4 } from "./Tillreconcile4";
import { Tillreconcile5 } from "./Tillreconcile5";
import { Endofday } from "./Endofday";
import { Itemsfordiscount } from "./Itemsfordiscount";
import { Discount_offer } from "./Discount_offer";
import { Discountoption } from "./Discountoption";
import { Discountapproval } from "./Discountapproval";
import { Discountconfirm } from "./Discountconfirm";
import { Scanner } from "./Scanner";
import ProtectedRoutingWhenLogin from "./ProtectedRoutingforlogin";
import Mainpage from "./ReturnComponents/Mainpage";
import DashBordMain from "./DashBordComponent/DashBordMain";
import ReturnTransection from "./ReturnComponents/ReturnTransection";
import AddItem from "./DashBordComponent/Mangement/MangeAllModal/AddItem";
import { useDispatch, useSelector } from "react-redux";
import { handleDipalyPort } from "./Redux/Reducer";
import Swal from "sweetalert2";
import shopandbuy from "./Shopandbuy";
import OpenRegister from "./UiComponents/OpenRegister";
import AddCustomerLoyality from "./AddCustomerLoyality";
import {isDev} from "./services/http-common";
function App() {
  const dispatch = useDispatch();
  const { DisplaPort } = useSelector((state) => state.Reducer);
  //  const [selectedPort, setSelectedPort] = useState(null);
  // useEffect(() => {
  //   const connectToSerialPort = async () => {
  //     try {
  //       const availablePorts = await navigator.serial.getPorts();

  //       if (availablePorts.length === 0) {
  //         // Swal.fire({
  //         //   title: "No serial ports available.",
  //         //   icon: "error",
          
  //         // });
  //         return;
  //       }

  //       console.log("All Ports:", availablePorts);
  //       dispatch(handleDipalyPort(availablePorts));
  //       // setPorts(availablePorts);
  //     } catch (error) {
  //       console.error("Error fetching serial ports:", error.message);
  //     }
  //   };

  //   connectToSerialPort();
  // }, []);
  // useEffect(() => {
  //   if (DisplaPort.length > 0 && !selectedPort) {
  //     // For simplicity, selecting the first available port
  //     const firstPort = DisplaPort[0];
  //     console.log('Using port:', firstPort);
  //     setSelectedPort(firstPort);

  //     // Open the selected port
  //     openSelectedPort(firstPort);
  //   }
  // }, [DisplaPort]);

  // const openSelectedPort = async (port) => {
  //   try {
  //     await port.open({ baudRate: 9600 });
  //     console.log('Serial port opened successfully.');
  //   } catch (error) {
  //     console.error('Error opening serial port:', error.message);
  //   }
  // };

  // const sendCommand = async (command) => {
  //   console.error(command);

  //   if (selectedPort) {
  //     try {
  //       const writer = selectedPort.writable.getWriter();
  //       const encoder = new TextEncoder();

  //       await writer.write(encoder.encode(command));
  //       console.log('Command sent successfully:', command);

  //       // Release the writer after writing
  //       writer.releaseLock();

  //     } catch (error) {
  //       console.error('Error writing to serial port:', error);
  //     }
  //   } else {
  //     console.error('Serial port is not open.');
  //   }
  // };

  // useEffect(() => {
  //   sendCommand('Welcome To Vast Retailer');

  //   // setTimeout(() => {
  //   //   sendCommand('                                                                      '); // Replace 'clear' with the command to clear the screen
  //   // }, 20000);
  // }, [selectedPort]);

  // This Is Comment!
  const Token = JSON.parse(localStorage.getItem("Token"));
  // ali pwa code start
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installButtonVisible, setInstallButtonVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setInstallButtonVisible(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      try {
        await deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;

        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the PWA installation");
        } else {
          console.log("User declined the PWA installation");
        }

        setDeferredPrompt(null);
        setInstallButtonVisible(false);
      } catch (error) {
        console.error("Error during PWA installation:", error);
        // Handle the error as needed
      }
    }
  };

  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  return (
    <>
      {installButtonVisible && !isDev  &&(
        <div className="row">
          <div>
            <div
              className="card p-2 mb-lg-0 text-white"
              data-tor="inview:bg(primary) , hover:bg(danger)"
              style={style}
            >
              <div className="hstack gap-3">
                <div className="p-2">
                  <strong className="fs-5 lh-1 text-dark">
                    V A S T R E T A I L
                  </strong>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn btn-outline-info rounded-3"
                    id="install-button"
                    onClick={handleInstallClick}
                  >
                    Download App
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={<ProtectedRoutingWhenLogin Component={Home} />}
            />
            <Route
              path="/openregister"
              element={<ProtectedRoutingWhenLogin Component={OpenRegister} />}
            />
            <Route
              path="/welcomesearch"
              element={<ProtectedRoutingWhenLogin Component={WelcomeSearch} />}
            />
            <Route
              path="/shopandbuy"
              element={<ProtectedRoutingWhenLogin Component={shopandbuy} />}
            />

            <Route
              path="/scan"
              element={<ProtectedRoutingWhenLogin Component={Scan} />}
            >
              {/* <Route path='/scan/cart' element={<ProtectedRoutingWhenLogin Component={Cart}/>}/> */}
              {/* <Route path='/scan' element={<ProtectedRoutingWhenLogin Component={Scanner}/>}/> */}
            </Route>

            <Route
              path="/sixth"
              element={<ProtectedRoutingWhenLogin Component={Sixth} />}
            />
            <Route
              path="/seventh"
              element={<ProtectedRoutingWhenLogin Component={Seventh} />}
            />
            <Route
              path="/enrollment"
              element={<ProtectedRoutingWhenLogin Component={Enrollment} />}
            />
            {/* <Route
              path="/accountverify"
              element={<ProtectedRoutingWhenLogin Component={Accountverify} />}
            /> */}
            <Route
              path="/enrollform"
              element={<ProtectedRoutingWhenLogin Component={Enrollform} />}
            />
            <Route
              path="/startoftheday"
              element={<ProtectedRoutingWhenLogin Component={Startoftheday} />}
            />
            <Route
              path="/tilloptions"
              element={<ProtectedRoutingWhenLogin Component={Tilloptions} />}
            />
            <Route
              path="/tillopen"
              element={<ProtectedRoutingWhenLogin Component={Tillopen} />}
            />
            <Route
              path="/tillclose"
              element={<ProtectedRoutingWhenLogin Component={Tillclose} />}
            />
            <Route
              path="/tillreconcile"
              element={<ProtectedRoutingWhenLogin Component={Tillreconcile} />}
            />
            <Route
              path="/tillreconcile2"
              element={<ProtectedRoutingWhenLogin Component={Tillreconcile2} />}
            />
            <Route
              path="/tillreconcile3"
              element={<ProtectedRoutingWhenLogin Component={Tillreconcile3} />}
            />
            <Route
              path="/tillreconcile4"
              element={<ProtectedRoutingWhenLogin Component={Tillreconcile4} />}
            />
            <Route
              path="/tillreconcile5"
              element={<ProtectedRoutingWhenLogin Component={Tillreconcile5} />}
            />
            <Route
              path="/endofday"
              element={<ProtectedRoutingWhenLogin Component={Endofday} />}
            />
            <Route
              path="/AddLoytaltiCutm"
              element={
                <ProtectedRoutingWhenLogin Component={AddCustomerLoyality} />
              }
            />
            {/* <Route
              path="/discount"
              element={<ProtectedRoutingWhenLogin Component={Discount_offer} />}
            /> */}
            {/* <Route
              path="/discountoption"
              element={<ProtectedRoutingWhenLogin Component={Discountoption} />}
            /> */}
            {/* <Route
              path="/discountapproval"
              element={
                <ProtectedRoutingWhenLogin Component={Discountapproval} />
              }
            /> */}
            {/* <Route
              path="/discountconfirm"
              element={
                <ProtectedRoutingWhenLogin Component={Discountconfirm} />
              }
            /> */}
            <Route
              path="/return"
              element={<ProtectedRoutingWhenLogin Component={Mainpage} />}
            />
            <Route
              path="/add-item"
              element={<ProtectedRoutingWhenLogin Component={AddItem} />}
            />
            <Route path="/ReturnTransection" element={<ReturnTransection />} />
            <Route
              path="/Dashbord"
              element={<ProtectedRoutingWhenLogin Component={DashBordMain} />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
