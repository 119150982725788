import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import DataService from "./services/requestApi";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch } from "react-redux";
import { SetTillForRecocile } from "./Redux/Reducer";
export const Tillreconcile = () => {
  const navigate = useNavigate();
  const { saasId, storeId ,tillId,registerId} = JSON.parse(localStorage.getItem("User_data"));
  const userData = JSON.parse(localStorage.getItem("User_data"));
  const Ref = useRef();
  const date =moment(new Date()).format("YYYY-MM-DD")
  const dispatch = useDispatch()
  const [data, setData] = useState({
    cash: "",
    usd: "",
    card: "",
    saas_id: saasId,
    store_id: storeId,
    till_id:tillId,
    exchange_certificate: "",
    gift_voucher: "",
    travel_cheque: "",
    credit_note: "",
    business_date:date
  });
  const TillReconcile = async () => {
    try {
      if(tillId){
        const response = await DataService.TillReconcile(data);
        if (response.data.status) {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            timer: 2000,
          });
          // dispatch(SetTillForRecocile(data.till_id))
          navigate("/tillreconcile2");
        }else{
          Swal.fire({
            title: "Data Already Saved",
            icon: "error",
            
          });
        }
        console.log("till Reconcile response", response);
      }else{
        Swal.fire({
          title: "Please Enter Till Id",
          icon: "error",
        });
      }
   
     
    } catch (error) {
      console.log("Till Reconcile error", error);
      Swal.fire({
        title: error.response.data.message,
        icon: "error",
      });
    }
  };

  // const [user, setUser] = useState(userData);
  // const IstillOpend = async () => {
  //   try {
  //     const response = await DataService.GetOpendTill(registerId);
  //     console.log(response.data);
  //     if (response.data.status) {
  //       const updatedUser = { ...user, tillId: response.data.data.till_id };
  //       localStorage.setItem("User_data", JSON.stringify(updatedUser));
  //       setUser(updatedUser);
  //     }else {
  //       Swal.fire({
  //         title: "Please Open Till",
  //         timer: 1500,
  //       });
  //       navigate("/tillopen");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate("/tilloptions");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    Ref.current.focus();
  }, []);

  //onchange function
  const handelonChange = (e) => {
    e.preventDefault();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />
      <Container>
        <Row>
          <div className="py-3 d-flex">
            <Link to={"/tilloptions"} className="text-dark">
              <AiOutlineArrowLeft className="fs-4" />
            </Link>
            <h5 className="mx-5">Till Reconcile</h5>
          </div>

          <Col md="5">
            <Row>
              <p> Select Tender and Amount</p>
              <Form className="h-25">
                {/* <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                    className=""
                  >
                    <h6 className="px-2">
                      Enter Your Till ID{" "}
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                        ref={Ref}
                        name="till_id"
                        value={data.till_id}
                        onChange={handelonChange}
                        placeholder="Enter Till Id"
                      ></Form.Control>
                    </div>
                  </div>
                </Col> */}
                <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                    className=""
                  >
                    <h6 className="px-2">
                       INR{" "}
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                         ref={Ref}
                        name="cash"
                        value={data.cash}
                        onChange={handelonChange}
                        placeholder="Enter INR Cash"
                      ></Form.Control>
                    </div>
                  </div>
                </Col>

                <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                  >
                    <h6 className="px-2">
                      USD Cash{" "}
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                        name="usd"
                        value={data.usd}
                        onChange={handelonChange}
                        placeholder="Enter USD Cash"
                      ></Form.Control>
                    </div>
                  </div>
                </Col>

                {/* <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                  >
                    <h6 className="px-2">
                      Credit Card
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                        name="card"
                        value={data.card}
                        onChange={handelonChange}
                        placeholder="Enter Amount"
                      ></Form.Control>
                    </div>
                  </div>
                </Col>

                <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                  >
                    <h6 className="px-2">
                      Gift Voucher{" "}
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                        name="gift_voucher"
                        value={data.gift_voucher}
                        onChange={handelonChange}
                        placeholder="Enter Amount"
                      ></Form.Control>
                    </div>
                  </div>
                </Col>

                <Col md="12 py-1">
                  <div
                    style={{
                      width: "450px",
                      background: "#f2f0f0",
                      height: "75px",
                      borderRadius: "7px",
                    }}
                  >
                    <h6 className="px-2">
                      Credit Note{" "}
                      <span className="float-end fs-5">
                        <AiOutlineArrowRight />
                      </span>
                    </h6>
                    <div className="px-3">
                      <Form.Control
                        name="credit_note"
                        value={data.credit_note}
                        onChange={handelonChange}
                        placeholder="Enter Amount"
                      ></Form.Control>
                    </div>
                  </div>
                </Col> */}
              </Form>
            </Row>
          </Col>

          <Col md="6">
            <div className="my-5">
              <Button
                className="text-White w-50 "
                variant="primary"
                onClick={TillReconcile}
              >
                <span className="float-start">Next</span>{" "}
                <Button className=" border float-end">Enter</Button>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
