import React, { useEffect, useState } from 'react';
import DataService from '../../services/requestApi';
const DisablePermotion = () => {
  const [formData, setFormData] = useState({
    date: '',
    storeId: '',
    start: '',
    end: ''
  });
  const { saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [storeIds, setStoreIds] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await DataService.DisblePermostion(formData);
    if(!response.data.status){
        throw new Error('Failed to Disable promotion time');
    }
    //   if (!response.ok) {
    //     throw new Error('Failed to create promotion time');
    //   }

      setSuccess(true);
      setFormData({
        date: '',
        storeId: '',
        start: '',
        end: ''
      });
    } catch (err) {
      setError('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const getStoreid=async ()=>{
    try {
      const response = await DataService.GetStoreBySaasid(saasId)
      console.log(response.data.data)
      setStoreIds(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getStoreid()
  }, [])
  

  const formStyle = {
    // backgroundColor: '#f8f9fa',
    borderRadius: '15px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '30px',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const headerStyle = {
    color: '#007bff',
    marginBottom: '25px',
    textAlign: 'center',
    fontWeight: 'bold',
  };

  const inputStyle = {
    borderRadius: '8px',
    border: '1px solid #ced4da',
    padding: '10px 15px',
    fontSize: '16px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '8px',
    padding: '12px 20px',
    fontSize: '18px',
    fontWeight: 'bold',
    transition: 'background-color 0.15s ease-in-out',
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <form onSubmit={handleSubmit} style={formStyle}>
            <h2 style={headerStyle}>Disable Promotion Time</h2>
            <div className="mb-4">
              <label htmlFor="date" className="form-label">Date</label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="storeId" className="form-label">Store ID</label>
              <select
                className="form-select"
                id="storeId"
                name="storeId"
                value={formData.storeId}
                onChange={handleChange}
                required
                style={inputStyle}
              >
                <option value="">Select a Store ID</option>
                {storeIds.map((el) => (
                  <option key={el.store_id} value={el.store_id}>{el.store_id}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="start" className="form-label">Start Time</label>
              <input
                type="time"
                className="form-control"
                id="start"
                name="start"
                value={formData.start}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="end" className="form-label">End Time</label>
              <input
                type="time"
                className="form-control"
                id="end"
                name="end"
                value={formData.end}
                onChange={handleChange}
                required
                style={inputStyle}
              />
            </div>
            <button 
              type="submit" 
              className="btn btn-primary w-100" 
              disabled={isLoading}
              style={buttonStyle}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
            {error && (
              <div className="alert alert-danger mt-3 d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <div>{error}</div>
              </div>
            )}
            {success && (
              <div className="alert alert-success mt-3 d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-circle-fill flex-shrink-0 me-2" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <div>Disable Promotion successfully!</div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default DisablePermotion;