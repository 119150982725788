import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import { Row, Col, Form } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import DataService from "./services/requestApi";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import { BASEURL } from "./services/http-common";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SetTillForRecocile } from "./Redux/Reducer";

export const Tillreconcile5 = () => {
  // const { tillidForRecon } = useSelector((state) => state.Reducer);
  const { saasId, storeId, registerId, tillId, storeName, userId, userName ,businessName} =
    JSON.parse(localStorage.getItem("User_data"));
  const { haderLine1 ,haderLine2} =
    JSON.parse(localStorage.getItem("Store_data"));
  const business_date =
    JSON.parse(localStorage.getItem("business_date"));
  const date = moment(new Date()).format("YYYY-MM-DD");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tillactualcount, setTillactualcount] = useState();
  const [tillphisicalcount, setTillphisicalcount] = useState();
  const [shortageaccess, setShortageaccess] = useState();
  const [totalSale, setTotalSale]= useState()
  const GetTodaySummery = async () => {
    try {
      const response = await DataService.ReconcileRepostPrint(
        business_date,
        saasId,
        storeId,
        tillId
      );
      console.log(
        "this Response for sale",
        response.data.data.till_physical_count
      );
      if (response.data.status) {
       setTillactualcount(response.data.data.till_actual_count);
        setTillphisicalcount(response.data.data.till_physical_count);
        setShortageaccess(response.data.data.shortage_access);
      }
    } catch (error) {
      console.log("this is catch", error);
    }
  };

  const GetNetSale = async()=>{
  try {
    const Response = await DataService.GetTillSales(business_date, saasId, storeId,tillId)
    setTotalSale(Response.data.data)
  } catch (error) {
    console.log(error)
  }
  }

  useEffect(() => {
    GetTodaySummery();
    GetNetSale()
  }, []);

  //   <----------------Handel KOT PRINT------------->
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("printing Start");
    },
    onAfterPrint: () => {
      dispatch(SetTillForRecocile(""));
            localStorage.clear();
            navigate("/login");
          
      // Swal.fire({
      //   text: "Till And Register Closed Successfully!",
      //   icon: "success", 
      //   confirmButtonText: "Ok",
      // }).then(async (result) => {
      //     if (result.isConfirmed) {
            
      //     }})
    },
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "p" || event.key === "P") {
        handleCloseTill();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleTabKeyPress = (e) => {
      if (e.key === "Escape") {
        navigate("/");
      }
    };

    window.addEventListener("keydown", handleTabKeyPress);

    return () => {
      window.removeEventListener("keydown", handleTabKeyPress);
    };
  }, []);

  const handleCloseTill = async () => {
    if (tillId == null)
      return Swal.fire({ title: "Till Id Not Found", timer: 2000 });
    const apiUrl = `${BASEURL.ENDPOINT_URL}/till/till-close/CLOSE/${saasId}/${storeId}/${tillId}/${registerId}`;
    try {
      const response = await axios.put(apiUrl);
      if (response.data.status) {
        const Register = response.data.data ? response.data.data : registerId;
       const RegistClose= await DataService.TillRegisterClose(saasId, storeId, Register);
       if(RegistClose.data.status){
        handlePrint()
       }
        
        // Swal.fire({
        //   title: "Want to Close Register",
        //   icon: "question",
        //   showDenyButton: true,
        //   confirmButtonText: "Yes",
        //   denyButtonText: "No",
        // }).then(async (result) => {
        //   if (result.isConfirmed) {
        //     await DataService.TillRegisterClose(saasId, storeId, Register);
        //     localStorage.clear();
        //     navigate("/login");
        //   } else if (result.isDenied) {
        //     localStorage.clear();
        //     navigate("/login");
        //   }
        // });
      } else {

        if(response.data.message == "Till Closed Already"){
          Swal.fire({
            title: response.data.message,
            icon: "error",
          }).then(async (result) => {
            if (result.isConfirmed) {
              dispatch(SetTillForRecocile(""));
              localStorage.clear();
              navigate("/login");
            
            }})
          
        }
      }
      // Perform any additional actions after a successful request
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors accordingly
    }
  };

  const newData = ["INR", "USD", "Credit Card", "Credit Notes", "Gift Voucher"];

  const finalData = newData
    .map((tender) =>
      tillactualcount?.find((item) => item.tender_name === tender)
    )
    .filter((item) => item !== undefined);

    const customLineStyle = {
      border: '1px solid black',
      margin: '8px 0',
    };
  return (
    <div>
      <Navbar className="justify-content-Between">
        <Container>
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr />

      <Container>
        <div className="d-flex my-5">
          <Link to={"/tilloptions"} className="text-dark">
            <AiOutlineArrowLeft />
          </Link>
          <h5 className="mx-4">Till Reconcile</h5>
        </div>

        <div>
          <Row ref={componentRef}>
            <Col md="6">
              <div
                style={{
                  maxWidth: "600px",
                  width: "100%",
               
                  height: "fit-content",
                  borderRadius: "7px",
                }}
                className=""
              >
                <Row>
                  {" "}
                  <p className="text-center fw-bold m-0">Till Summary Report</p>

                  <p className="text-center fw-bold m-0">{storeName}</p>
                  <p className="text-center fw-bold m-0">{haderLine1}</p>
                  <p className="text-center fw-bold m-0">{haderLine1}</p>
                  <p className=" text-center fw-bold">Business Date:  {business_date}</p>

                  
                </Row>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Till ID:
                    </h5>
                    <h5 style={{ fontSize: "14px" }}>{tillId}</h5>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      User ID:
                    </h5>
                    <h5 style={{ fontSize: "14px" }}>{userId}</h5>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Register ID:
                    </h5>
                    <h5 style={{ fontSize: "14px" }}>{registerId}</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      User ID:
                    </h5>
                    <h5 style={{ fontSize: "14px" }}>{userName}</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      marginBottom: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      User Name:
                    </h5>
                    <h5 style={{ fontSize: "14px" }}>{businessName}</h5>
                  </div>
                </div>{" "}
              </div>
            </Col>
            <Col md="6">
              <div
                style={{
                  maxWidth: "600px",
                  width: "100%",
               
                  height: "fit-content",
                  borderRadius: "7px",
                }}
                className=""
              >
                <Row>
                  {" "}
                  <p className="text-center fw-bold">Till Actual Count</p>
                </Row>
                <Row className="px-3" >
                  <Col className="col d-flex justify-content-start">
                    <p className="text-center fw-bold">Tender Name </p>
                  </Col>
                  <Col className="col d-flex justify-content-end">
                    <p className="text-center fw-bold">Total Amount</p>
                  </Col>
                </Row>
                {finalData &&
                  finalData.map((item) => (
                    <Row className="px-3" style={{height:"20px"}}>
                      <Col className="col d-flex justify-content-start">
                        <p className="text-center">{item.tender_name}</p>
                      </Col>
                        <Col className="col d-flex justify-content-end">
                        <p className="text-center">{item.sum_amount}</p>
                      </Col>
                    </Row>
                  ))}
                  <div className='fw-bold mt-3' style={customLineStyle}></div>
                {totalSale &&
                  totalSale?.sales.map((item) => (
                    <>
                    <Row className="px-3" style={{height:"20px"}}>
                      <Col className="col d-flex justify-content-start">
                        <p className="text-center">Gross Sale</p>
                      </Col>
                                            <Col className="col d-flex justify-content-end">
                        <p className="text-center">{item.total_item_value ? item.total_item_value :"0"}</p>
                      </Col>
                    </Row>
                   <Row className="px-3" style={{height:"20px"}}>
                      <Col className="col d-flex justify-content-start">
                        <p className="text-center">Discount</p>
                      </Col>
                                            <Col className="col d-flex justify-content-end">
                        <p className="text-center">{item.item_discount ? item.item_discount: "0"}</p>
                      </Col>
                    </Row>
                    {totalSale && totalSale?.returns.map((el)=>{return(<>
                    <Row className="px-3" style={{height:"20px"}}>
                    <Col className="col d-flex justify-content-start">
                      <p className="text-center">Return </p>
                    </Col>
                                        <Col className="col d-flex justify-content-end">
                      <p className="text-center">{el.total_item_net ? el.total_item_net :"0" }</p>
                    </Col>
                  </Row>
                     <Row className="px-3" style={{height:"20px"}}>
                      <Col className="col d-flex justify-content-start">
                        <p className="text-center">Net Sale</p>
                      </Col>
                                            <Col className="col d-flex justify-content-end">
                        <p className="text-center">{item.total_item_net ? item.total_item_net - el.total_item_net :"0" }</p>
                      </Col>
                    </Row>
                 </> )})}
                    </>))}

                  

              </div>
            </Col>
            <Col className="mt-2" md="6">
              <div
                style={{
                  maxWidth: "600px",
                  width: "100%",
               
                  height: "fit-content",
                  borderRadius: "7px",
                }}
                className=""
              >
                <Row>
                  {" "}
                  <p className="text-center fw-bold">Till Physical Count</p>
                </Row>

                <Row>
                  <Col>
                    <p className="text-center fw-bold">Tender Name </p>
                  </Col>
                  <Col>
                    <p className="text-center fw-bold">Total Count</p>
                  </Col>
                  <Col>
                    <p className="text-center fw-bold">Total Amount</p>
                  </Col>
                </Row>

                {tillphisicalcount?.map((item) => {
                  return (
                    <>
                     {item?.denomination.map((el)=>{return(<>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 250</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd250}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd250* 250}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 500</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd500}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd500 * 500}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 1000</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd1000}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd1000 * 1000}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 5000</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd5000}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd5000 * 5000}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 10000</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd10000}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd10000 * 10000}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 25000</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd25000}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd25000 * 25000}</p>
                        </Col>
                      </Row>
                     <Row style={{height:"20px"}}>
                        <Col>
                          <p className="text-center">INR 50000</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd50000}</p>
                        </Col>
                        <Col>
                          <p className="text-center">{el?.iqd50000 * 50000}</p>
                        </Col>
                      </Row>
                      </>)}) }
                      <Row className="px-3" style={{height:"20px"}}>
                        <Col className="col d-flex justify-content-start">
                          <p className="text-center">Cash</p>
                        </Col>
                                                <Col className="col d-flex justify-content-end px-4">
                          <p className="text-center">{item?.cash}</p>
                        </Col>
                      </Row>
                      <Row className="px-3" style={{height:"20px"}}>
                        <Col className="col d-flex justify-content-start">
                          <p className="text-center">Usd</p>
                        </Col>
                                                <Col className="col d-flex justify-content-end px-4">
                          <p className="text-center">{item?.usd}</p>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <p className="text-center">Credit Card</p>
                        </Col>
                        <Col>
                          <p className="text-center">{item?.card}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <p className="text-center">Credit Note</p>
                        </Col>
                        <Col>
                          <p className="text-center">{item?.credit_note}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="text-center">Gift Voucher</p>
                        </Col>
                        <Col>
                          <p className="text-center">{item?.gift_voucher}</p>
                        </Col>
                      </Row> */}
                    </>
                  );
                })}
              </div>
            </Col>
            <Col className="mt-2" md="6">
              <div
                style={{
                  maxWidth: "600px",
                  width: "100%",
               
                  height: "fit-content",
                  borderRadius: "7px",
                }}
                className=""
              >
                <Row>
                  {" "}
                  <p className="text-center fw-bold">Shortage Excess</p>
                </Row>

                <Row className="px-3" style={{height:"20px"}}>
                  <Col className="col d-flex justify-content-start">
                    <p className="text-center fw-bold">Tender Name </p>
                  </Col>
                                    <Col className="col d-flex justify-content-end">
                    <p className="text-center fw-bold">Value</p>
                  </Col>
                </Row>

               {tillphisicalcount && tillphisicalcount.map((el)=>{return( <><Row className="px-3" style={{height:"20px"}}>
                  <Col className="col d-flex justify-content-start">
                    <p className="text-center">INR</p>
                  </Col>
                                    <Col className="col d-flex justify-content-end">
                    <p className="text-center">{shortageaccess?.IQD == null ? el?.cash :shortageaccess?.IQD}</p>
                  </Col>
                </Row>
                <Row className="px-3">
                  <Col className="col d-flex justify-content-start">
                    <p className="text-center">Usd</p>
                  </Col>
                                    <Col className="col d-flex justify-content-end">
                    <p className="text-center">{shortageaccess?.usd== null ? el?.usd :shortageaccess?.usd}</p>
                  </Col>
                </Row>
                <Row>
                  {" "}
                  <p className="text-center fw-bold">** End Till Summary Report **</p>
                </Row>
                {/* <Row>
                  <Col>
                    <p className="text-center">Credit Card</p>
                  </Col>
                  <Col>
                    <p className="text-center">{shortageaccess?.card == null ? -el?.card :shortageaccess?.card }</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p className="text-center">Credit Note</p>
                  </Col>
                  <Col>
                    <p className="text-center">{shortageaccess?.credit_note== null ? - el?.credit_note :shortageaccess?.credit_note}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="text-center">Gift Voucher</p>
                  </Col>
                  <Col>
                    <p className="text-center">
                      {shortageaccess?.gift_voucher == null ? - el?.gift_voucher :shortageaccess?.gift_voucher}
                    </p>
                  </Col>
                </Row> */}
                </>)})}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="py-3">
                <Button
                  className="text-White  border  mx-2"
                  variant="none"
                  style={{ width: "250px" }}
                >
                  <span className="float-start my-2">Cancel</span>{" "}
                  <Button
                    className=" border float-end text-dark"
                    variant="none"
                  >
                    Esc
                  </Button>
                </Button>

                <Button
                  className="text-White  border  mx-2"
                  variant="primary"
                  onClick={handleCloseTill}
                  style={{ width: "250px" }}
                >
                  <span className="float-start my-2">Print ( P )</span>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
