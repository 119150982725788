import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { 
    Card, 
    CardContent, 
    CardActions, 
    Grid,
    ThemeProvider,
    createTheme
  } from '@mui/material';
import ButtonForPrintscreen from '../DashBordComponent/StoreinsightsModals/ButtonForPrintscreen';
import { useReactToPrint } from 'react-to-print';
//   import PrintIcon from '@mui/icons-material/Print';
//   import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
  });
const OpenCashDrawermoadal = ({setIsManager,CloseModal}) => {
    const [open, setOpen] = React.useState(false);
  const handleOpen = () =>{
      setOpen(true)
      setTimeout(() => {
          handlePrint()
          
      }, 1000);
};
  const handleClose = () => setOpen(false);
  const componentRef = useRef();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };   
      const [currentTime, setCurrentTime] = useState(new Date());
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const managerUserId = "MGR001"; // Replace with actual manager ID

  const printReceipt = () => {
    setIsPrinting(true);
    window.print();
    setIsPrinting(false);
  };

  const openCashDrawer = () => {
    console.log("Opening cash drawer...");
    // In a real implementation, you would use appropriate hardware APIs or
    // send a signal to the POS system to open the cash drawer
  }; 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      CloseModal()
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      setIsManager(false)
      console.log("printing End");
    },
  });
  return (
    <div className='d-flex justify-content-center'>
      <Button sx={{marginTop:"20px"}} variant="contained" onClick={handleOpen}>Open Cash Drawer</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <ThemeProvider theme={theme}>
      <Card 
        elevation={3} 
        sx={{ 
          maxWidth: 400, 
          margin: 'auto', 
          marginTop: theme.spacing(4) 
        }}
      >
        <CardContent  ref={componentRef} sx={{ padding: theme.spacing(3) }}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Receipt
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Manager ID:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">{managerUserId}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Time:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {currentTime.toLocaleTimeString()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between', padding: theme.spacing(2) }}>
            <ButtonForPrintscreen handlePrint={handlePrint} setShowPdf={setOpen}/>
          {/* <Button 
            variant="contained" 
            color="primary" 
            startIcon={<PrintIcon />}
            onClick={printReceipt}
            disabled={isPrinting}
          >
            {isPrinting ? 'Printing...' : 'Print'}
          </Button>
          <Button 
            variant="outlined" 
            color="secondary" 
            startIcon={<MonetizationOnIcon />}
            onClick={openCashDrawer}
          >
            Open Drawer
          </Button> */}
        </CardActions>
      </Card>
    </ThemeProvider>
        </Box>
      </Modal>
    </div>
  )
}

export default OpenCashDrawermoadal