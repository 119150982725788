import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaRegCalendarAlt } from "react-icons/fa";
import {
  Button,
  TextField,
  IconButton,
  Typography,
  Popover,
  Box,
  CircularProgress,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment/moment'
import Swal from 'sweetalert2'
import DataService from '../../../services/requestApi';
export default function FlatDiscountForm({value}) {
  const [isLoading, setIsLoading] = useState(false)
  const [startDateAnchorEl, setStartDateAnchorEl] = useState(null)
  const [endDateAnchorEl, setEndDateAnchorEl] = useState(null)
  const { saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [storeIds, setStoreIds] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      storeId: '',
      discount: 0,
      startdate: new Date(),
      enddate: new Date(),
    },
  })

  const handleOpenPopover = (setter) => (event) => {
    setter(event.currentTarget)
  }

  const handleClosePopover = (setter) => () => {
    setter(null)
  }

  async function onSubmit(values) {
    setIsLoading(true)
    try {
      console.log(values)
      const response = await DataService.ApplyFlatDiscount(values)

      if (!response.data.status) {
        throw new Error('Failed to create flat discount')
      }
      Swal.fire({
        title: 'Success',
        text: 'Flat discount created successfully',
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        title: 'Error',
        text: error instanceof Error ? error.message : 'Failed to create flat discount. Please try again.',
        variant: 'destructive',
      })
    } finally {
      setIsLoading(false)
    }
  }
  
  const getStoreid=async ()=>{
    try {
      const response = await DataService.GetStoreBySaasid(saasId)
      console.log(response.data.data)
      setStoreIds(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(value == "4"){
      getStoreid()
    }
  }, [value])
  const inputStyle = {
    // borderRadius: '8px',
    border: '1px solid #ced4da',
    padding: '10px 15px',
    fontSize: '16px',
    height: ' 60px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  };
  const headerStyle = {
    color: '#007bff',
    marginBottom: '25px',
    textAlign: 'center',
    fontWeight: 'bold',
  };
  const formStyle = {
    // backgroundColor: '#f8f9fa',
    borderRadius: '15px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '30px',
    maxWidth: '500px',
    margin: '0 auto',
  };
  return (
    <Box style={formStyle} component="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, mx: 'auto', mt: 4 }}>
      {/* <TextField
        label="Store ID"
        placeholder="Enter store ID"
        {...register('storeId', { required: 'Store ID is required' })}
        error={!!errors.storeId}
        helperText={errors.storeId?.message}
      /> */}
      <h2 style={headerStyle}>Apply Flat Discount</h2>
      <div >
              {/* <label htmlFor="storeId" className="form-label">Store ID</label> */}
              <select
                className="form-select"
                id="storeId"
                name="storeId"
                {...register('storeId', { required: 'Store ID is required' })}
                 error={!!errors.storeId}
                 helperText={errors.storeId?.message}
                // value={formData.storeId}
                // onChange={handleChange}
                // required
                style={inputStyle}
              >
                <option value="">Select a Store ID</option>
                {storeIds.map((el) => (
                  <option key={el.store_id} value={el.store_id}>{el.store_id}</option>
                ))}
              </select>
            </div>
      
      <TextField
        label="Discount (%)"
        type="number"
        placeholder="Enter discount percentage"
        {...register('discount', {
          required: 'Discount is required',
          min: { value: 0, message: 'Discount must be at least 0' },
          max: { value: 100, message: 'Discount must be at most 100' },
        })}
        error={!!errors.discount}
        helperText={errors.discount?.message}
        onChange={(e) => setValue('discount', e.target.valueAsNumber)}
      />
      
      <TextField
        label="Start Date"
        type="date"
        {...register('startdate', { required: 'Start date is required' })}
        error={!!errors.startdate}
        helperText={errors.startdate?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        label="End Date"
        type="date"
        {...register('enddate', { required: 'End date is required' })}
        error={!!errors.enddate}
        helperText={errors.enddate?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {/* <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body1">Start Date</Typography>
        <IconButton onClick={handleOpenPopover(setStartDateAnchorEl)}>
          <FaRegCalendarAlt />
        </IconButton>
        <Popover
          open={Boolean(startDateAnchorEl)}
          anchorEl={startDateAnchorEl}
          onClose={handleClosePopover(setStartDateAnchorEl)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <DatePicker
            value={watch('startdate')}
            onChange={(date) => setValue('startdate', date)}
            minDate={new Date()}
            maxDate={new Date('2100-01-01')}
            renderInput={(params) => <TextField {...params} />}
          />
        </Popover>
      </Box> */}

      {/* <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="body1">End Date</Typography>
        <IconButton onClick={handleOpenPopover(setEndDateAnchorEl)}>
          <FaRegCalendarAlt />
        </IconButton>
        <Popover
          open={Boolean(endDateAnchorEl)}
          anchorEl={endDateAnchorEl}
          onClose={handleClosePopover(setEndDateAnchorEl)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <DatePicker
            value={watch('enddate')}
            onChange={(date) => setValue('enddate', date)}
            minDate={new Date()}
            maxDate={new Date('2100-01-01')}
            renderInput={(params) => <TextField {...params} />}
          />
        </Popover>
      </Box> */}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={20} /> : null}
      >
        {isLoading ? 'Creating...' : 'Create Flat Discount'}
      </Button>
    </Box>
  )
}
