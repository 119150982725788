import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import DataService from "../services/requestApi";
import { TenderReportModal } from "./StoreinsightsModals/TenderReportModal";
import tenderimage from "../Images/tenderimage.png"
function Tender() {
  const [openTenderReportModal, setOpenTenderReportModal] = useState(false);
  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  return (
    <div>
      {" "}
      <Container>
        <Row>
          <Col>
            <div>
              <img
                className=" rounded-4"
                style={{
                  background: "lightgray 50% / cover no-repeat",
                  boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
                  width: "95%",
                  height: "350px",
                }}
                src={tenderimage}
                alt=""
              />
            </div>
          </Col>
          <Col>
            <Button
              style={style}
              className="w-100"
              variant="success"
              id="dropdown-basic"
              onClick={() => {
                setOpenTenderReportModal(!openTenderReportModal);
              }}
            >
              Tender Report
            </Button>
          </Col>
        </Row>
      </Container>
      <TenderReportModal
        show={openTenderReportModal}
        setOpenTenderReportModal={setOpenTenderReportModal}
      />
    </div>
  );
}

export default Tender;
