import moment from 'moment';
import React from 'react';
import Barcode from 'react-barcode';
import { PrintProvider, Print } from 'react-easy-print';
import { useSelector } from 'react-redux';

function CreditRiceaptModal({CrediNotesresp,TotalReturn,CreditValid}) {
    const SelectedReturn = useSelector((state) => state.Reducer.SelectedReturn);
  const totalProductQty = SelectedReturn.reduce((total, item) => total + parseInt(item.productQty), 0);
  const { haderLine1,haderLine2,haderLine3,haderLine4,futterLine1,futterLine2,futterLine3,futterLine4,futterLine5,futterLine6,futterLine7  } = JSON.parse(localStorage.getItem("Store_data"))
  const { storeName } = JSON.parse(localStorage.getItem("User_data"))
  console.log(" SelectedReturn", SelectedReturn)
  const styles = {
    fontFamily: 'Verdana',
    fontSize: 'medium',
    fontWeight: 'bold',
  };

  const customLineStyle = {
    border: '1px solid black',
    margin: '8px 0',
  };
  const table = {
    border: '1px solid black',
    margin: '8px 0',
  };
  const receiptContent = (
    <div style={styles}>
      <p style={{fontSize: "1.6rem"}} className='d-flex  justify-content-around fw-bold'>{storeName}</p>
      <p style={{fontSize: "1.4rem"}} className='d-flex  justify-content-around fw-bold'>{haderLine1}</p>
      <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>{haderLine2}</p>
        <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>{haderLine3}</p>
        <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>{haderLine4}</p>
      <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold'>Credit Note</p>

     
            <p style={{fontSize: "1.25rem"}}  className='d-flex  justify-content-around fw-bold' >Date:{moment(CrediNotesresp?.TRANSACTION_DATE).format('DD/MM/YYYY,h:mm:ss a')}</p>
        
      <div className='fw-bold' style={customLineStyle}></div>

      <div className='container' style={{fontSize: "1.25rem"}}>
        <div className="row  fw-bold">
          <div className="col">
          <div className='d-flex justify-content-center'  >
               <Barcode  value={CrediNotesresp.creditNote_id}  width={2} height={50}/>
              </div>
          </div>
        
           
       
        
        
         
      <div className='fw-bold' >********************************</div>
        </div>
      </div>
      <table className='w-100 mt-5 mx-md-auto' style={{fontSize: "1.2rem"}}>
        <thead>
        <tr className='border-dark' style={{ borderBottomWidth: '2px' }}>
            <th>Item</th>
            <th  className='px-3'>Qty</th>
            <th className='px-3'>Rate</th>
            {/* <th>Value</th> */}
          </tr>
        </thead>
        <tbody>
       
          <>
          
          {SelectedReturn && SelectedReturn.map((el)=>{return(
          <><tr className='fw-bold'>
          <td>{el?.item_id}</td>
          <td className='text-center'>{el.productQty}</td>
          <td className='text-center'>{el.price}</td>
          {/* <td>{el.newPrice}</td> */}
        </tr>
        <tr className='fw-bold'>
          <td>{el?.item_name.slice(0, 25)}</td>
          {/* <td>{el.newPrice}</td> */}
        </tr>
          {/* {el.message&&
        <tr>
          <td colSpan={2}>Message: {el?.message.slice(0, 25)}</td>
        </tr>
          } */}
        {/* <tr className='fw-bold'>
          <td colspan="3" className='m-0' style={{fontSize:"1rem"}}>{el.item_name}</td>
          <td>{el.discount}%</td>
        </tr> */}
        </>
          )})}
        {/* <tr className='fw-bold'>
          <td>2772669</td>
          <td>1x</td>
          <td>4500</td>
          <td>= 4500</td>
        </tr> */}

        </>
        
          
        </tbody>
      </table>
     

      <div className='fw-bold' style={customLineStyle}></div>

      <div className='container' style={{fontSize: "1.25rem"}}>
        <div className="row  fw-bold">
          <div className="col">
            <p>Total:</p>
          </div>
        
          <div className="col">
            <p>INR</p>
          </div>
          <div className="col">
            <p> {TotalReturn}</p>
          </div>
        </div>
      </div>


      
      <div className='container' style={{fontSize: "1.2rem"}}>
        <div className="col">
        
          <div className="col p-2 fw-bold">
            <p className='fw-bold'>Authorised by : </p>
         <p className='fw-bold'>Accounts   :  </p>
          </div> 
        
        </div>
      </div>

      <div className='container' style={{fontSize: "1.2rem"}}>
        <div className="col">
        
          <div className="col p-2 fw-bold">
            <p className='fw-bold'>Not valid after 180 Days</p>

         <p className='fw-bold'>valid upto: {CreditValid}
</p>

          </div> 
        
        </div>
      </div>

      {/* <div className='container' style={{fontSize: "1.1rem"}}>
        <div className="row">
       
          
            <p className='text-nowrap text-center'>Date: {moment(Date.now()).format('L,h:mm:ss a')}</p>
            <div className='d-flex justify-content-center'  >
               <Barcode  value={barcodeData}  width={2} height={50}/>
              </div>
        </div>
      </div> */}


    </div>
  );

  return (
     <PrintProvider>
      <Print 
      >
       <div style={styles}>
            {receiptContent}
          </div>

        
      </Print>
    </PrintProvider>
  );
}

export default CreditRiceaptModal;







