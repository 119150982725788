import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Box
} from '@mui/material';
import { MdPlaylistAdd } from 'react-icons/md';
import DataService from '../../../services/requestApi';
export default function AdduserModal() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      user_name: '',
      mobile_number: '',
      password: '',
      store_name: '',
      user_type: 'user',
      store_id: '',
      saas_id: '',
      register_id: '',
      base_currency: 'INR',
      country: 'India',
      status: true,
      business_name: '',
      category_req: '',
    },
  });

  const onSubmit =async (data) => {
    setIsSubmitting(true);
    // Simulate API call
    try {
        const response = await DataService.UserMaster(data);
        if(response.status === 200) {
         setTimeout(() => {
           console.log(data);
           setIsSubmitting(false);
           handleClose();
         }, 2000);
        }
    } catch (error) {
        console.log(error)
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <div>
        <MdPlaylistAdd
        onClick={handleOpen}
                      size={22}
                      color="green"
                      className="mouse-pointer"
                
                    />
      {/* <Button variant="contained" color="primary" >
        Open Registration Form
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Card>
            <CardHeader 
              title="User Registration" 
              subheader="Enter your details to create a new account"
            />
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="user_name"
                      control={control}
                      rules={{ required: 'Username is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Username"
                          fullWidth
                          error={!!errors.user_name}
                          helperText={errors.user_name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="mobile_number"
                      control={control}
                      rules={{ 
                        required: 'Mobile number is required',
                        pattern: {
                          value: /^\d{10}$/,
                          message: 'Please enter a valid 10-digit mobile number'
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Mobile Number"
                          fullWidth
                          error={!!errors.mobile_number}
                          helperText={errors.mobile_number?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ 
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters'
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="password"
                          label="Password"
                          fullWidth
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="store_name"
                      control={control}
                      rules={{ required: 'Store name is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Store Name"
                          fullWidth
                          error={!!errors.store_name}
                          helperText={errors.store_name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="user_type"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>User Type</InputLabel>
                          <Select value='CASHIER' {...field} label="User Type">
                            <MenuItem value="CASHIER">CASHIER</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="store_id"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Store ID" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="saas_id"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="SaaS ID" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="register_id"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Register ID" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="base_currency"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Base Currency" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Country" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Active Status"
                        />
                      )}
                    />
                    <FormHelperText>Check this box if the account should be active.</FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="business_name"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Business Name" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="category_req"
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} label="Category" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}