import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import AddItemModal from './MangeAllModal/AddItemModal';
import { BASEURL } from '../../services/http-common';
import { MdDelete, MdEdit, MdPlaylistAdd ,MdPrint } from "react-icons/md";

// import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import GetItemBarcodeModal from "../../UiComponents/GetItemBarcodeModal";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
function ItemMaster({ loading, handlePageChange, data, count,GetItemReport,setData }) {
  const navigate = useNavigate();
  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px"
  };
  const [modalShow, setModalShow] = useState(false);
    const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [flag, setFlag] = useState(false);
    const handleDelete = async (row) => {
      const { saasId } = JSON.parse(localStorage.getItem("User_data")) || {};
   
      try {
        const response = await fetch(
          `${BASEURL.ENDPOINT_URL}/item/inactive-item/${row.item_id}/${saasId}`,
          {
            method: "PUT",
          }
        );
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const jsonData = await response.json();
    
        if (jsonData && jsonData.status === true) {
          GetItemReport()
          setFlag(!flag);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (invoiceNumber) {
        axios
          .get(
            `${BASEURL.ENDPOINT_URL}/search/get-result/${storeId}/${saasId}/${invoiceNumber}`
          )
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => {
            console.log("ERR CUSTOMER INVOICE NO.", err);
          });
      } else {
        setData(data);
      }
    };
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setInvoiceNumber(value);
  
      if (!value || value.length === 0) {
        GetItemReport();
      }
    };












    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectionChange = (row) => {
      const isSelected = selectedRows.includes(row);
      const newSelectedRows = isSelected
        ? selectedRows.filter((id) => id !== row)
        : [...selectedRows, row];
    
      setSelectedRows(newSelectedRows);
    
      if (!isSelected) {
        
        console.log("Selected Row Data:", row);
      }
    };
    const [selectAll, setSelectAll] = useState(false);

    // useEffect(() => {
    //  console.log("selectedRows",selectedRows)
    // }, [selectedRows])
    const handleSelectAll = () => {
      setSelectAll(!selectAll);
      if (!selectAll) {
        // If selecting all, set all rows to selected
        setSelectedRows(data);
      } else {
        // If unselecting all, clear selected rows
        setSelectedRows([]);
      }
    };
  const columns = [
    
    {
      name: "Select Item",
      center: true,
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectAll || selectedRows.includes(row)}
          onChange={() => handleSelectionChange(row)}
        />
      ),
    },
    {
      name: "Item Code",
      selector: (row) => row.item_code,
      cell: (row) => (
        <div>{row.item_code}</div>
      ),
    },
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      cell: (row) => (
        <div>{row.item_name}</div>
      ),
    },
    {
      name: "Concept Code",
      center: true,
      selector: (row) => row.conc_id,
    },
    // Add other columns as needed
    // {
    //   name: "Description",
    //   center: true,
    //   selector: (row) => row.description,
    // },
    // {
    //     name: 'Discount',
    //     center: true,
    //     selector: row => row.discount,
    // },
    {
      name: "Price",
      center: true,
      selector: (row) => row.price,
    },
    // {
    //   name: "Class Code",
    //   center: true,
    //   selector: (row) => row.hsn_code,
    // },
    // {
    //   name: "Tax",
    //   center: true,
    //   selector: (row) => row.tax,
    // },
    {
      name: "Action",
      center: true,
      selector: (row) => (
        <>
        <div className="d-flex">
          <div className="me-2" style={{cursor:'pointer'}}>
            <MdPlaylistAdd
              size={22}
              color="green"
              className="mouse-pointer" 
              onClick={() => navigate("/add-item")}
            />
          </div>
          <div style={{cursor:'pointer'}} className="me-2">
            <MdDelete
              size={22}
              color="red"
              className="mouse-pointer"
              onClick={() => handleDelete(row)}
            />
          </div>
          <div style={{cursor:'pointer'}}>
            <MdEdit
              size={22}
              color="var(--primary1)"
              className="mouse-pointer"
              onClick={() => {
                setSelectedRow(row);
                setAddUpdateItemModalIsOpen(true);
              }}
            />
          </div>
          <div style={{cursor:'pointer'}} className="me-2">
          <MdPrint
              size={22}
              color="green"
              className="mouse-pointer"
              onClick={() => setModalShow(true)} // Fix the onClick event here
            />
          </div>
        </div>
        
       </>
      ),
    },
  ];
 
  
  return (
    <>
    <div className="mx-5" >

      {/* Add other buttons as needed */}
      <div style={{    position: "relative",
    top: "40px"}}>
  <label>
    <input
      type="checkbox"
      checked={selectAll}
      onChange={handleSelectAll}
    />
    Select All
  </label>
</div>

<div className='container ' 
        style={{position:"relative", left:"80px"}}  >
        <Row className='m-1'>
          <Col xs={12} sm={8}
          >
            <InputGroup className="" 
            >
              <FormControl
                placeholder="Search Item Name"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={invoiceNumber}
                onChange={handleInputChange}
              />
              <Button 
              onClick={handleSubmit} 
              style={{width: '77px',
  height: '40px',
  flexShrink: 0,
  background: '#565ADD',}}><FaSearch /> </Button>

            </InputGroup>
          </Col>
        </Row>

       


      </div>
 <hr/>
      <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={count}
        progressPending={loading}
        onChangePage={(page) => handlePageChange(page)}
      />
  
       <AddItemModal
       GetItemReport={GetItemReport}
         addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
         setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
         row={selectedRow}
         setFlag={setFlag}
         flag={flag}
       />
    </div>
       <GetItemBarcodeModal  item={selectedRows}  show={modalShow} onHide={() => setModalShow(false)} />
       </>
  );
}

export default ItemMaster;
