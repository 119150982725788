import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

let initialState = {
  values: [],
  login: [],
  cartDetails: [],
  displayCart: [],
  UserData: "",
  DicountOnTotal: "",
  LinkCustomer: [],
  RetrunData: [],
  PaymentDetail: [],
  Returnheader: "",
  RetundAmount: 0,
  SelectedReturn: [],
  Salesmandata: [],
  save_product_id: "",
  SalesOverview: "",
  InvaintorySummery: "",
  TotalCutomer: "",
  Low_StockQty: "",
  totalNumberofItam: "",
  DisplaPort: [],
  tillidForRecon: "",
  openPriceCheckModel: false,
  CouponCode:"",
  getPeromotionRule:"",
  StartTime:"",
};

const Reducer = createSlice({
  name: "Reducer",
  initialState,
  reducers: {
    provideData: (state, action) => {
      console.log("data", action.payload);
      state.values = [action.payload]
    },
    PerMotionrule: (state, action) => {
      console.log("data", action.payload);
      state.getPeromotionRule = action.payload;
    },
    RemoveLoyltiyuser: (state) => {
      state.values = [];
    },
    loginDetails: (state, action) => {
      console.log("login", action.payload);
      state.login = [action.payload];
      state.UserData = action.payload.user_data;
      console.log("after login", state.login, state.UserData);
    },

    getDataToCart: (state, action) => {
      console.log("dispatch data", action.payload);
      state.cartDetails = [action.payload];
      console.log("store data to the cartDetails", state.cartDetails);
    },
    RemoveCart: (state, action) => {
      state.displayCart = [];
    },
    displayDataToCart: (state, action) => {
      console.log("this is filter dispatch  data", action.payload.price);
      if(state.displayCart.length == 0){
        state.StartTime= new Date().toISOString()
        // console.log("StartTime",moment(state.StartTime).format())
      }
      const cart = state.displayCart?.filter(
        (val) => val.item_id === action.payload.item_id
      );
      if (cart.length > 0) {
        const index = state.displayCart.findIndex(
          (val) => val.item_id === action.payload.item_id
        );
        let qty = state.displayCart[index].product_qty;
        const incqty = ++qty;
        state.displayCart[index].product_qty = incqty;
        state.displayCart[index].productQty = incqty;
        const price = state.displayCart[index].price;
        const total = price * incqty;
        state.displayCart[index].newPrice = total;
      } else {
     
        action.payload.salesManId = "";
        action.payload.salesMan = "";
        action.payload.newPrice = action.payload.price;
        action.payload.productQty = action.payload.product_qty;
        state.displayCart = [...state.displayCart, action.payload];
        console.log("add data to the displayCart", state.displayCart);
        console.log(state.displayCart[0].item_name);
      }
    },
    incrementQuantity: (state, action) => {
      console.log("incrementing item quantity", action.payload);
      // console.log(displayCart[].item_name)
      //state.increQuantity=[action.payload]
      const index = state.displayCart.findIndex(
        (val) => val.item_id === action.payload
      );
      console.log(index);
      let qty = state.displayCart[index].product_qty;

      const incqty = ++qty;
      state.displayCart[index].product_qty = incqty;
      const price = state.displayCart[index].price;
      const total = price * incqty;
      state.displayCart[index].newPrice = total;
    },
    decrementQuantity: (state, action) => {
      console.log("incrementing item quantity", action.payload);
      // console.log(displayCart[].item_name)
      //state.increQuantity=[action.payload]
      const index = state.displayCart.findIndex(
        (val) => val.item_id === action.payload
      );
      console.log(index);
      let qty = state.displayCart[index].product_qty;

      if (qty > 1) {
        const decqty = --qty;
        state.displayCart[index].product_qty = decqty;
        const price = state.displayCart[index].price;
        const total = price * decqty;
        state.displayCart[index].newPrice = total;
      }
    },
    handleQtyChange: (state, action) => {
      // Check if the provided value is greater than 0.001
      // if (action.payload.value > 0.001) {
      // Map over the displayCart and update the item with the matching item_id
      const updatedDisplayCart = state.displayCart.map((item) =>
        item.item_id === action.payload.id
          ? {
              ...item,
              product_qty: action.payload.value,
              productQty: action.payload.value,
              newPrice: item.price * action.payload.value,
            }
          : item
      );

      // Return a new state object with the updated displayCart
      console.log(updatedDisplayCart);
      return {
        ...state,
        displayCart: updatedDisplayCart,
      };
      // }

      // If the value is not greater than 0.001, return the current state
      // return state;
    },
    RemoveItem: (state, action) => {
      //   const FilterData=state.displayCart.filter(item => item.item_id !== action.payload)
      const updatedProductData = state.displayCart.filter(
        (item) => item.item_id !== action.payload
      );
      return { ...state, displayCart: updatedProductData };
    },

    PriceOverride: (state, action) => {
      console.log(action.payload.itemId);
      const index = state.displayCart.findIndex(
        (val) => val.item_id === action.payload.itemId
      );
      state.displayCart[index].price = action.payload.price;
      state.displayCart[index].newPrice = action.payload.price;
    },
    handleactualPrice: (state, action) => {
      const index = state.displayCart.findIndex(
        (val) => val.item_id === action.payload
      );
      const TotalDis =
        (state.displayCart[index].actual_price -
          state.displayCart[index].price) *
        state.displayCart[index].productQty;
      console.log("thsi total", TotalDis);
      const updatedDisplayCart = state.displayCart.map((item) =>
        item.item_id === action.payload
          ? {
              ...item,
              discount: TotalDis,
              price: item.actual_price,
              // newPrice: item.price * action.payload.value,
            }
          : item
      );
      console.log(updatedDisplayCart);
      return { ...state, displayCart: updatedDisplayCart };
    },

    AddItemDiscount: (state, action) => {
      console.log("this paylode for dicaount", action.payload);
      const index = state.displayCart.findIndex(
        (val) => val.item_id === action.payload.itemId
      );
      const currentItem = state.displayCart[index];
      console.log("this currentItem", currentItem.price);
      if (action.payload.DiscountType === "percentage") {
        const discountPercentage = action.payload.price;
        if (state.displayCart[index]?.discount > 0) {
          const discountAmount =
            (currentItem.actual_price * discountPercentage) / 100;
          console.log("this discount", discountAmount);
          console.log("currentItem.actual_price", currentItem.actual_price);
          state.displayCart[index].Discountper = action.payload.price;
          state.displayCart[index].discount = Math.ceil(discountAmount);
          state.displayCart[index].price =
            currentItem.actual_price - Math.ceil(discountAmount);
          state.displayCart[index].newPrice =
            (currentItem.actual_price - Math.ceil(discountAmount)) *
            currentItem.productQty;
          console.log("percentage wala", discountAmount);
          // Swal.fire({
          //   title: "Discount Updated Successfully",
          //   icon: "success",
          //   timer: 2000,
          // });

          // if (discountAmount === 0) {
          //   state.displayCart[index].actual_price = discountAmount;

          // }
        } else {
          // state.displayCart[index].actual_price = state.displayCart[index].price
          const discountAmount =
            (currentItem.actual_price * discountPercentage) / 100;
          state.displayCart[index].discount = Math.ceil(discountAmount);
          console.log(
            "percentage wala",
            Math.ceil(discountAmount),
            discountAmount
          );

          console.log(currentItem.price);
          console.log(currentItem.actual_price);
          state.displayCart[index].Discountper = action.payload.price;
          state.displayCart[index].price =
            currentItem.price - Math.ceil(discountAmount);
          state.displayCart[index].newPrice =
            (currentItem.actual_price - Math.ceil(discountAmount)) *
            currentItem.productQty;
          // Swal.fire({
          //   title: "Discount Updated Successfully",
          //   icon: "success",
          //   timer: 2000,
          // });
        }
      } else if (state.displayCart[index]?.discount > 0) {
        console.log("yhaan aya");
        state.displayCart[index].Discountper = "";
        state.displayCart[index].discount = Math.ceil(action.payload.price);
        state.displayCart[index].price =
          state.displayCart[index].actual_price -
          Math.ceil(action.payload.price);
        state.displayCart[index].newPrice =
          (state.displayCart[index].actual_price -
            Math.ceil(action.payload.price)) *
          currentItem.productQty;
        // Swal.fire({
        //   title: "Discount Update Successfully",
        //   icon: 'success',
        //   timer: 2000
        // })
        if (action.payload.price == 0) {
          state.displayCart[index].actual_price = action.payload.price;
        }
      } else {
        console.log(
          "Amount wise discount",
          Math.ceil(action.payload.price),
          action.payload.price
        );
        state.displayCart[index].actual_price = state.displayCart[index].price;
        state.displayCart[index].discount = Math.ceil(action.payload.price);
        state.displayCart[index].price =
          state.displayCart[index].price - Math.ceil(action.payload.price);
        state.displayCart[index].newPrice =
          (state.displayCart[index].actual_price -
            Math.ceil(action.payload.price)) *
          currentItem.productQty;
        console.log("this item", state.displayCart[0], `its index ${index}`);
        // Swal.fire({
        //   title: "Discount Added Successfully",
        //   icon: 'success',
        //   timer: 2000
        // })
      }
    },

    ApplyDiscountOnTotal: (state, action) => {
      // state.DicountOnTotal = action.payload
      console.log(action.payload);
      if (action.payload.type == "per") {
        const totalamount = state.displayCart.reduce(
          (sum, item) => sum + item.newPrice,
          0
        );
        const TotalDiscount =
          (totalamount * Number(action.payload.amount)) / 100;
        state.DicountOnTotal = Math.ceil(TotalDiscount);
      } else if (action.payload.type == "Amt") {
        // const totalamount = state.displayCart.reduce((sum, item) => sum + item.newPrice, 0)
        state.DicountOnTotal = Math.ceil(action.payload.amount);
      }
    },
    //Apply Discount on Every Item by percentage on every item
    ApplyonEveryItemDiscount: (state, action) => {
      const discountPercentage = action.payload;
      return {
          ...state,
          displayCart: state.displayCart.map(item => {
            if (item.discount !== undefined && item.discount > 0) {
              return item; // Return the item unchanged if already discounted
          }
              const discountAmount = Math.floor((item.price * discountPercentage) / 100); // Calculate discount amount without decimals
              const newPrice = item.price - discountAmount;
              console.log(discountAmount)
              return {
                  ...item,
                  discount: discountAmount, // Set discount amount without decimals
                  newPrice: Math.max(0, newPrice) * item.productQty, // Ensure newPrice is not negative
                  price: Math.max(0, newPrice), // Update price field without decimals
              };
          }),
      };
  },


    HandelLinkCustomer: (state, action) => {
      // console.log('Reducer for Link Customer',action.payload)
      state.LinkCustomer = action.payload;
    },

    //Return Data State Store
    RetrunWithReceipt: (state, action) => {
      console.log("ReducerAction", action.payload);
      action.payload.invoice_details.map((item) => {
        item["new_price"] = item.item_net;
        item["productQty"] = item.product_qty;
        item["message"] = "";
      });
      state.RetrunData = action.payload.invoice_details;
      state.PaymentDetail = action.payload.payment_details_list;
      state.Returnheader = action.payload.invoice_header;
    },

    HandelReturnItemQty: (state, action) => {
      if (action.payload.value > 0.001) {
        // Map over the displayCart and update the item with the matching item_id
        const index = state.RetrunData.findIndex(
          (val) => val.item_id === action.payload.id
        );
        const divide = state.RetrunData[index].discount/state.RetrunData[index].productQty
        const ActulDis = state.RetrunData[index].discount-divide
        console.log(ActulDis,action.payload.value,)
        const updatedReturnCart = state.RetrunData.map((item) =>
          item.item_id === action.payload.id
            ? {
                ...item,
                product_qty: action.payload.value,
                productQty: action.payload.value,
                discount:ActulDis,
                // item_discount: item_discount* action.payload.value,
                // productQty: action.payload.value,
                new_price: item.price * action.payload.value,
                item_net: item.price * action.payload.value,
              }
            : item
        );

        // Return a new state object with the updated displayCart
        console.log(updatedReturnCart);
        return {
          ...state,
          RetrunData: updatedReturnCart,
        };
      }

      // If the value is not greater than 0.001, return the current state
      return state;
    },

    handelReturnAmount: (state, action) => {
      state.SelectedReturn = action.payload;
      console.log("this selscted item", state.SelectedReturn);
      const totalRetunamount = action.payload.reduce(
        (sum, item) => sum + item.item_net,
        0
      );
      console.log(totalRetunamount);
      state.RetundAmount = totalRetunamount;
    },
    RemoveReturn: (state, action) => {
      state.RetrunData = [];
      state.SelectedReturn = [];
      state.RetundAmount = "";
    },

    HandelSalesman: (state, action) => {
      console.log(action.payload.sales_man_id);
      state.Salesmandata = [...state.Salesmandata, action.payload];
      state.displayCart.map((el) =>
        el.conc_id == action.payload.conc_id
          ? (el["salesManId"] = action.payload.sales_man_id)
          : el
      );
      console.log(
        state.displayCart[0].conc_id == action.payload.conc_id,
        state.displayCart[0].salesManId
      );
    },
    handleInventoryMasterRequest: (state, payload) => {},
    handleUploadPicRequest: (state, payload) => {
      state.load = true;
    },
    //getCATEGORY
    handelGetCategoryRequest: (state, payload) => {},
    handelGetCategoryResponse: (state, payload) => {
      // console.log("category_list", payload);
      state.category_list = payload.data;
    },
    resetProductId: (state, payload) => {
      state.save_product_id = "";
    },
    handleAddItemToStoreResponse: (state, payload) => {
      console.log("its id", payload.payload);
      state.save_product_id = payload.payload.data;
    },

    handleSalesOverview: (state, action) => {
      // console.log(action.payload)
      state.SalesOverview = action.payload;
      //  console.log(state.SalesOverview)
    },

    handleInventory: (state, action) => {
      state.InvaintorySummery = action.payload ? action.payload : 0;
    },
    handletotalcustomer: (state, action) => {
      state.TotalCutomer = action.payload;
    },
    handleLowStock: (state, action) => {
      state.Low_StockQty = action.payload;
    },
    handleTotalNumberOfiteam: (state, action) => {
      state.totalNumberofItam = action.payload;
    },
    handleDipalyPort: (state, action) => {
      state.DisplaPort = action.payload;
    },

    handelDisplaycart: (state, action) => {
      console.log(action.payload);
      state.displayCart = action.payload;
    },
    RemoveInovice: (state, action) => {
      state.DicountOnTotal = "";
    },
    SetMessage: (state, action) => {
      console.log(action.payload);
      const updatedReturnCart = state.SelectedReturn.map((item) =>
        item.item_id === action.payload.id
          ? {
              ...item,
              message: action.payload.message,
            }
          : item
      );

      // Return a new state object with the updated displayCart
      console.log(updatedReturnCart);
      return {
        ...state,
        SelectedReturn: updatedReturnCart,
      };
    },
    SetRetrunMessage: (state, action) => {
      console.log(action.payload);
      const updatedReturnCart = state.RetrunData.map((item) =>
        item.item_id === action.payload.id
          ? {
              ...item,
              message: action.payload.message,
            }
          : item
      );

      // Return a new state object with the updated displayCart
      // console.log(updatedReturnCart)
      return {
        ...state,
        RetrunData: updatedReturnCart,
      };
    },
    SetTillForRecocile: (state, action) => {
      state.tillidForRecon = action.payload;
    },
    openPriceCheck: (state, action) => {
      state.openPriceCheckModel = action.payload;
    },
    
    BOGOAPPLIED:(state, action)=>{
      const updatedData = state.displayCart.map(item => {
        const discountedItem = action.payload.find(discounted => discounted.itemCode === item.item_code);
        if (discountedItem) {
            return {
                ...item,
                actual_price: discountedItem.itemPrice,
                price: Math.ceil(discountedItem.finalDiscountedPriceOfItem/discountedItem.quantity) ,
                newPrice:discountedItem.finalDiscountedPriceOfItem,
                discount: Math.floor(discountedItem.discount/discountedItem.quantity) ,
                productQty:discountedItem.quantity,
                product_qty:discountedItem.quantity,
            };
        }
        return item;
    });

    return {
      ...state,
      displayCart: updatedData,
    };
    },

    ApplyeCoupn:(state, action)=>{
      console.log(action.payload)
      if(action.payload.discount_type =='amt'){
        const updatedcart = state.displayCart.map(item=>
          item.discount == 0?{
            ...item,
            discount:action.payload.discountAmount,
            price:item.price - action.payload.discountAmount,
            newPrice:(item.newPrice - action.payload.discountAmount)*item.productQty
          }:item
        )
        return {
          ...state,
          displayCart: updatedcart,
          CouponCode:action.payload.couponCode
        };
      }else{
        const updatedcart = state.displayCart.map(item=>
          item.discount == 0?{
            ...item,
            discount:( item.actual_price * Number(action.payload.discountPercent))/100,
            price: item.price - ( item.actual_price * Number(action.payload.discountPercent))/100,
            newPrice: (item.actual_price - (item.actual_price * Number(action.payload.discountPercent)) / 100) * item.productQty,
          }:item
        )
        return {
          ...state,
          displayCart: updatedcart,
          CouponCode:action.payload.couponCode
        };
      }
    }

  },
});
export const {
  PerMotionrule,
  ApplyeCoupn,  
  BOGOAPPLIED,
  openPriceCheck,
  SetTillForRecocile,
  SetRetrunMessage,
  SetMessage,
  RemoveInovice,
  handelDisplaycart,
  handleDipalyPort,
  handleTotalNumberOfiteam,
  handleLowStock,
  handletotalcustomer,
  handleInventory,
  handleSalesOverview,
  handleAddItemToStoreResponse,
  resetProductId,
  handelGetCategoryRequest,
  handleUploadPicRequest,
  handleInventoryMasterRequest,
  HandelSalesman,
  RemoveReturn,
  handelReturnAmount,
  HandelReturnItemQty,
  RetrunWithReceipt,
  HandelLinkCustomer,
  ApplyDiscountOnTotal,
  AddItemDiscount,
  handleactualPrice,
  PriceOverride,
  RemoveLoyltiyuser,
  RemoveCart,
  RemoveItem,
  handleQtyChange,
  provideData,
  loginDetails,
  getDataToCart,
  ApplyonEveryItemDiscount,
  displayDataToCart,
  incrementQuantity,
  decrementQuantity,
} = Reducer.actions;
export default Reducer.reducer;
