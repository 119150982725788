import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataService from "../../services/requestApi";
import moment from "moment";
import Swal from "sweetalert2";
const ItemHistory = (props) => {

    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [itemCode, setItemCode] = useState("")
  const [itemHistory, setItemHistory] = useState([])
  const GetItemHistoryByitemcode=async ()=>{
    try {
        if(!itemCode){
            Swal.fire({
                title:"Please Enter Item Code",
                icon: "warning",
            })
            return
        }
      const response = await DataService.ItemHistoryBycode(saasId, storeId,date,itemCode)
      console.log("Get item history", response.data)
      setItemHistory(response.data.data)
    } catch (error) {
        console.log("error", error)
    }
  }

  const columns = [
    {
      name: "Invoice Number",
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Total Gross",
      selector: (row) => row.invoiceTotal,
      sortable: true,
    },
    {
      name: "Total Net",
      selector: (row) => row.netValue,
      sortable: true,
    },
    {
      name: "Discount",
      selector: (row) => row.discountTotal,
      sortable: true,
    },
    {
      name: "Start Transaction Datetime",
      selector: (row) => row.startTransactionDateTime,
      sortable: true,
    },
    {
      name: "End Transaction Datetime",
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    // {
    //   name: "CITY",
    //   selector: (row) => row.address1,
    //   sortable: true,
    // },
    // {
    //   name: "AREA",
    //   selector: (row) => row.address2,
    //   sortable: true,
    // },
    // {
    //   name: "STAFF ID",
    //   selector: (row) => row.address3,
    //   sortable: true,
    // },
    // {
    //   name: "STAFF NAME",
    //   selector: (row) => row.salesManName,
    //   sortable: true,
    // },
  ];
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Loylity Card Issue Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex my-3">

        <Form.Control
          type="date"
          className="w-30 mx-2"
          id="exampleColorInput"
          defaultValue="#563d7c"
          title="Choose your color"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        />
        <Form.Control
        type="text"
        placeholder="Search by  Item Code"
        className="w-30 mx-2"
        id="exampleColorInput"
        value={itemCode}
        onChange={(e) => {
            setItemCode(e.target.value);
          }
        }
        />
        <Button onClick={()=>GetItemHistoryByitemcode()} > Search</Button>
        </div>

        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          columns={columns}
          responsive={true}
          fixedHeader={true}
          data={itemHistory}
          // progressPending={loading}
          pagination
          paginationServer
        />
      </Modal.Body>
    </Modal>
  );
};

export default ItemHistory;
