import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import DataService from "../../.././services/requestApi";
import Swal from "sweetalert2";
function UpdateStoremasterModal(props) {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const { selectedRow ,selectedRowData} = props;
  const { userId } = JSON.parse(localStorage.getItem("Store_data"));
  const [userDataId, setUserDataId] = useState(userId);
  const [storeLogoPath, setStoreLogoPath] = useState("");
  const [storeName, setStoreName] = useState("Kriti");
  const [city, setCity] = useState("Kanpur");
  const [state, setState] = useState("Bihar");
  const [country, setCountry] = useState("India");
  const [address, setAddress] = useState("Kanpur");
  const [taxable, setTaxable] = useState(true);
  const [gstCode, setGstCode] = useState("8479");
  const [hsnCode, setHsnCode] = useState("2311");
  const [storeType, setStoreType] = useState("medium");
  const [exclusiveTax, setExclusiveTax] = useState("487.34");
  const [bankAccount, setBankAccount] = useState("948472837384244");
  const [bankIfsc, setBankIfsc] = useState("NO12345");
  const [bankBranch, setBankBranch] = useState("Mumbai");
  const [paymentQrCode, setPaymentQrCode] = useState("paytm");
  const [receiptFormat, setReceiptFormat] = useState("1");
  const [inclusiveTax, setInclusiveTax] = useState("484.34");
  const [tnc, setTnc] = useState("The");
  const [email, setEmail] = useState("k@gmail.com");
  const [phoneNo, setPhoneNo] = useState("");
  const [headerLine1, setHeaderLine1] = useState("");
  const [headerLine2, setHeaderLine2] = useState("");
  const [futterLine1, setFutterLine1] = useState("");
  const [futterLine2, setFutterLine2] = useState("");
  const [futterLine3, setFutterLine3] = useState("");
  const [futterLine4, setFutterLine4] = useState("");
  const [futterLine5, setFutterLine5] = useState("");
  const [futterLine6, setFutterLine6] = useState("");
  const [futterLine7, setFutterLine7] = useState("");
  useEffect(() => {
    console.log(selectedRowData)
    if (selectedRowData) {
      setStoreName(selectedRowData.store_name || "");
      setCity(selectedRowData.city || "");
      setState(selectedRowData.state || "");
      setCountry(selectedRowData.country || "");
      setAddress(selectedRowData.address || "");
      setTaxable(selectedRowData.taxable || true);
      setGstCode(selectedRowData.gstCode || "");
      setHsnCode(selectedRowData.hsn_code || "");
      setStoreType(selectedRowData.storeType || "");
      setExclusiveTax(selectedRowData.exclusiveTax || "");
      setBankAccount(selectedRowData.bank_account || "");
      setBankIfsc(selectedRowData.bank_ifsc || "");
      setBankBranch(selectedRowData.bank_branch || "");
      setPaymentQrCode(selectedRowData.paymentQrCode || "");
      setReceiptFormat(selectedRowData.receiptFormat || "");
      setInclusiveTax(selectedRowData.inclusiveTax || "");
      setTnc(selectedRowData.tnc || "");
      setEmail(selectedRowData.email || "");
      setPhoneNo(selectedRowData.phoneNo || "");
      setHeaderLine1(selectedRowData.headerLine1 || "");
      setHeaderLine2(selectedRowData.headerLine2 || "");
      setFutterLine1(selectedRowData.futterLine1 || "");
      setFutterLine2(selectedRowData.futterLine2 || "");
      setFutterLine3(selectedRowData.futterLine3 || "");
      setFutterLine4(selectedRowData.futterLine4 || "");
      setFutterLine5(selectedRowData.futterLine5 || "");
      setFutterLine6(selectedRowData.futterLine6 || "");
      setFutterLine7(selectedRowData.futterLine7 || "");
    }
  }, [selectedRowData]);
  
  const handleSubmitStoreMasterData = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("store_logo", storeLogoPath);
      // formData.append('user_id', userDataId);
      formData.append("store_id", storeId);
      formData.append("saas_id", saasId);
      formData.append("store_name", storeName);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("country", country);
      formData.append("address", address);
      formData.append("taxable", taxable?.toString());
      formData.append("gst_code", gstCode);
      formData.append("hsn_code", hsnCode);
      formData.append("store_type", storeType);
      formData.append("exclusive_tax", exclusiveTax);
      formData.append("bank_account", bankAccount);
      formData.append("bank_ifsc", bankIfsc);
      formData.append("bank_branch", bankBranch);
      formData.append("payment_qr_code", paymentQrCode);
      formData.append("receipt_format", receiptFormat);
      formData.append("inclusive_tax", inclusiveTax);
      formData.append("tnc", tnc);
      formData.append("e_mail", email);
      formData.append("phone_no", phoneNo);
      formData.append("headerLine1", headerLine1);
      formData.append("headerLine2", headerLine2);
      formData.append("futterLine1", futterLine1);
      formData.append("futterLine2", futterLine2);
      formData.append("futterLine3", futterLine3);
      formData.append("futterLine4", futterLine4);
      formData.append("futterLine5", futterLine5);
      formData.append("futterLine6", futterLine6);
      formData.append("futterLine7", futterLine7);

      const response = await DataService.UpdateStoreMaster(
        selectedRow,
        formData
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Details Added Successfully in Store Master",
          icon: "success",
          confirmButtonText: "Done",
          timer: 3000,
        });
        props.setAddUpdateStoreModalIsOpen(false);
      }
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Something Not Working ",
        icon: "error",
        confirmButtonColor: "red",
        confirmButtonText: "Retry",
      });
    }
  };

  //stylings
  const inputStyle = {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "12px",
    border: "1px solid #ddd",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const ButtonStyle = {
    width: "50%",
    borderRadius: "8px",
    background: "#0067CE",
    color: "#fff",
    padding: "12px",
    cursor: "pointer",
    border: "none",
    transition: "background 0.3s ease-in-out",
  };

  inputStyle["&:hover"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  ButtonStyle[":hover"] = {
    background: "#00509E",
  };

  inputStyle["&:focus"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
    outline: "none",
  };

  ButtonStyle[":focus"] = {
    outline: "none",
  };

  inputStyle["&:active"] = {
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  ButtonStyle[":active"] = {
    background: "#003366",
  };

  //-------------------------

  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ paddingLeft: "15px" }}
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Store Master</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Card>
            <CardBody>
              <Form>
                <Row className="mt-2 container">
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter  Store Code"
                        style={inputStyle}
                        // onChange={handleInputChange}
                        // name="store_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex" style={{ marginBottom: "20px" }}>
                      <label
                        className="mt-2"
                        htmlFor="fileInput"
                        style={{ fontSize: "16px", marginRight: "10px" }}
                      >
                        Uplaod Logo:
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => setStoreLogoPath(e.target.files[0])}
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Address"
                        style={inputStyle}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        name="address"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Select City"
                        style={inputStyle}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        name="city"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Select State"
                        style={inputStyle}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        name="state"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Enter User ID"
                        style={inputStyle}
                        value={userDataId}
                        onChange={(e) => setUserDataId(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Store Name"
                        style={inputStyle}
                        value={storeName}
                        onChange={(e) => setStoreName(e.target.value)}
                        name="store_name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        placeholder="Select Your Country"
                        styles={{ height: "100px", width: "95%" }}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter GST Code"
                        style={inputStyle}
                        value={gstCode}
                        onChange={(e) => setGstCode(e.target.value)}
                        name="gst_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter  HSN Code"
                        style={inputStyle}
                        value={hsnCode}
                        onChange={(e) => setHsnCode(e.target.value)}
                        name="hsn_code"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Enter Store Type"
                        style={inputStyle}
                        value={storeType}
                        onChange={(e) => setStoreType(e.target.value)}
                        name="store_type"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        required={true}
                        placeholder="Enter Payment QR Code "
                        style={inputStyle}
                        value={paymentQrCode}
                        onChange={(e) => setPaymentQrCode(e.target.value)}
                        name="payment_qrcode"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="email"
                        placeholder="Enter email "
                        style={inputStyle}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="number"
                        placeholder="Phone number "
                        style={inputStyle}
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        name="phone_no"
                      />
                    </FormGroup>
                  </Col>{" "}
                 
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="headerLine1"
                        placeholder="Enter HeaderLine 1"
                        style={inputStyle}
                        value={headerLine1}
                        onChange={(e) => setHeaderLine1(e.target.value)}
                        name="headerLine1"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="headerLine2"
                        placeholder="Enter HeaderLine 2"
                        style={inputStyle}
                        value={headerLine2}
                        onChange={(e) => setHeaderLine2(e.target.value)}
                        name="headerLine2"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine1"
                        placeholder="Enter FooterLine 1 "
                        style={inputStyle}
                        value={futterLine1}
                        onChange={(e) => setFutterLine1(e.target.value)}
                        name="futterLine1"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine2"
                        placeholder="Enter FooterLine 2"
                        style={inputStyle}
                        value={futterLine2}
                        onChange={(e) => setFutterLine2(e.target.value)}
                        name="futterLine2"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine3"
                        placeholder="Enter FooterLine 3"
                        style={inputStyle}
                        value={futterLine3}
                        onChange={(e) => setFutterLine3(e.target.value)}
                        name="futterLine3"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine4"
                        placeholder="Enter FooterLine 4"
                        style={inputStyle}
                        value={futterLine4}
                        onChange={(e) => setFutterLine4(e.target.value)}
                        name="futterLine4"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine5"
                        placeholder="Enter FooterLine 5"
                        style={inputStyle}
                        value={futterLine5}
                        onChange={(e) => setFutterLine5(e.target.value)}
                        name="futterLine5"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine6"
                        placeholder="Enter FooterLine 6"
                        style={inputStyle}
                        value={futterLine6}
                        onChange={(e) => setFutterLine6(e.target.value)}
                        name="futterLine6"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="futterLine7"
                        placeholder="Enter FooterLine 7"
                        style={inputStyle}
                        value={futterLine7}
                        onChange={(e) => setFutterLine7(e.target.value)}
                        name="futterLine7"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="checkbox"
                        checked={taxable} // Use checked for checkbox input
                        onChange={(e) => setTaxable(e.target.checked)} // Update the state based on whether it's checked
                        style={{
                          width: "20px", // Adjust the width for the checkbox
                          height: "20px",
                          boxShadow: "0 1px 12px rgba(36, 36, 39, .12)",
                          borderRadius: ".3rem",
                        }}
                        name="taxable"
                      />
                      <label style={{ marginLeft: "10px" }}>Taxable</label>{" "}
                      {/* Optional label */}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex justify-content-center fw-bold">
                      <button
                        className="btn btn-secondary fw-bold"
                        style={ButtonStyle}
                        onClick={handleSubmitStoreMasterData}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateStoremasterModal;
