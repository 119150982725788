// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdt_TableHead {
    border-bottom: 1px solid var(--primary1);
}`, "",{"version":3,"sources":["webpack://./src/components/retailer-dashboard/master/item-master/index.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C","sourcesContent":[".rdt_TableHead {\n    border-bottom: 1px solid var(--primary1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
