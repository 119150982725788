import React, { useEffect, useState } from 'react'

const ButtonForPrintscreen = ({handlePrint,setShowPdf}) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.shiftKey) {
            if (event.key === 'C' || event.key === 'c') {
              setShowPdf(false)
            } else if (event.key === 'P' || event.key === 'p') {
              handlePrint()
            }
          }
        }
      
        document.addEventListener('keydown', handleKeyDown)
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown)
        }
      }, [])
      const [closeHovered, setCloseHovered] = useState(false)
      const [printHovered, setPrintHovered] = useState(false)
      const buttonStyle = {
        padding: '12px 24px',
        fontSize: '16px',
        fontFamily: 'Georgia, serif',
        fontWeight: 'normal' ,
        border: '2px solid #2c3e50',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        margin: '0 15px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative' ,
        backgroundColor: '#ffffff',
        color: '#2c3e50',
        minWidth: '150px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      }
      
      const closeButtonStyle = {
        ...buttonStyle,
        borderColor: '#c0392b',
        color: closeHovered ? '#ffffff' : '#c0392b',
        backgroundColor: closeHovered ? '#c0392b' : '#ffffff',
        transform: closeHovered ? 'translateY(-3px)' : 'translateY(0)',
      }
      
      const printButtonStyle = {
        ...buttonStyle,
        borderColor: '#27ae60',
        color: printHovered ? '#ffffff' : '#27ae60',
        backgroundColor: printHovered ? '#27ae60' : '#ffffff',
        transform: printHovered ? 'translateY(-3px)' : 'translateY(0)',
      }
      
      const iconStyle = {
        marginRight: '10px',
        fontSize: '18px',
      }
      
      const shortcutStyle = {
        position: 'absolute' ,
        bottom: '-20px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#34495e',
        color: '#ffffff',
        padding: '2px 6px',
        borderRadius: '3px',
        fontSize: '10px',
        transition: 'all 0.3s ease',
        opacity: 0,
      }
      
      const hoverShortcutStyle = {
        ...shortcutStyle,
        opacity: 1,
      }
  return (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        // height: '100vh', 
        backgroundColor: '#ecf0f1',
        padding: '20px',
      }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <button
            style={closeButtonStyle}
            onClick={() => setShowPdf(false)}
            onMouseEnter={() => setCloseHovered(true)}
            onMouseLeave={() => setCloseHovered(false)}
          >
            <span style={iconStyle}>✖</span>
            Close
            <span style={closeHovered ? hoverShortcutStyle : shortcutStyle}>Shift + C</span>
          </button>
          <button
            style={printButtonStyle}
            onClick={handlePrint}
            onMouseEnter={() => setPrintHovered(true)}
            onMouseLeave={() => setPrintHovered(false)}
            autoFocus
          >
            <span style={iconStyle}>🖨️</span>
            Print
            <span style={printHovered ? hoverShortcutStyle : shortcutStyle}>Shift + P</span>
          </button>
        </div>
      </div>
  )
}

export default ButtonForPrintscreen