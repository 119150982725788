// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageNumbers {
  list-style: none;
  display: flex;
}

.pageNumbers li {
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
  background-color: white;
}

.pageNumbers li.active {
  background-color: blue;
  color: white;
}

.pageNumbers li button {
  background-color: transparent;
  border: none;
  color: '';
  font-size: 1.5rem;
  cursor: pointer;
}
.pageNumbers li button:hover {
  background-color: black;
  color: white;
}
.pageNumbers li button:focus {
  outline: none;
}

/* .loadmore {
  padding: 1rem;
  background-color: transparent;

  color: black;
  font-size: 1.2rem;
  border: 1px solid black;
  cursor: pointer;
} */

.MainCard:hover{
  background-color: #797676;
  border-radius: 10px;
  box-shadow: 0px 3px 9px rgba(36, 36, 36, 0.5);
  /* box-shadow:aliceblue; */
  
  
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,aAAa;AACf;;AAEA;;;;;;;;GAQG;;AAEH;EACE,yBAAyB;EACzB,mBAAmB;EACnB,6CAA6C;EAC7C,0BAA0B;;;AAG5B","sourcesContent":[".pageNumbers {\r\n  list-style: none;\r\n  display: flex;\r\n}\r\n\r\n.pageNumbers li {\r\n  padding: 10px;\r\n  border: 1px solid black;\r\n  cursor: pointer;\r\n  background-color: white;\r\n}\r\n\r\n.pageNumbers li.active {\r\n  background-color: blue;\r\n  color: white;\r\n}\r\n\r\n.pageNumbers li button {\r\n  background-color: transparent;\r\n  border: none;\r\n  color: '';\r\n  font-size: 1.5rem;\r\n  cursor: pointer;\r\n}\r\n.pageNumbers li button:hover {\r\n  background-color: black;\r\n  color: white;\r\n}\r\n.pageNumbers li button:focus {\r\n  outline: none;\r\n}\r\n\r\n/* .loadmore {\r\n  padding: 1rem;\r\n  background-color: transparent;\r\n\r\n  color: black;\r\n  font-size: 1.2rem;\r\n  border: 1px solid black;\r\n  cursor: pointer;\r\n} */\r\n\r\n.MainCard:hover{\r\n  background-color: #797676;\r\n  border-radius: 10px;\r\n  box-shadow: 0px 3px 9px rgba(36, 36, 36, 0.5);\r\n  /* box-shadow:aliceblue; */\r\n  \r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
