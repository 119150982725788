import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import DataService from '../../services/requestApi';
import Swal from "sweetalert2";
import GetUserModal from "./MangeAllModal/GetUserModal";
import AdduserModal from "./MangeAllModal/AdduserModal";
import { FaSearch } from "react-icons/fa";

function UserMaster({loading, data,setData, count, handlePageChange, GetUser }) {
  const [modalShow, setModalShow] = useState(false);
  console.log(data);
  const [selectedrow, setSelectedrow]=useState('')
  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  // Search api call by key word and set data in setSalesman 
  const [searchKey, setSearchKey] = useState("")
  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer); // Clear any existing timer
    }

    if (searchKey !== "") {
      // Set a debounce timer of 500ms
      const timer = setTimeout(() => {
        GetUserBySearch(searchKey);
      }, 500);
      setDebounceTimer(timer);
    } else {
      GetUser(); // Call the function to fetch all salesmen if no search key is provided
    }

    // Cleanup function to clear the timer if the component is unmounted
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [searchKey]);
  const GetUserBySearch = async (searchKey) => {
    try {
      const response = await DataService.GetUserBySearch(searchKey)
      console.log('this is search man',response.data.data)
      setData(response.data.data)
      //  setCount(response.data.count)
    } catch (error) {
      console.log('this is catch',error)
    }
  }

  const DeleteUserByid =async (id) => {
    try {
      const response =await DataService.DeleteUser(id)
      console.log(response)
      if (response.status === 200) {
        Swal.fire({
          title: "User deleted successfully",
          icon: "success",
          timer: 2000,
        });
        GetUser();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      name: "User Id",
      center: true,
      selector: (row) => row.party_name,
      cell: (row) => {
        return (
          <>
            <div>{row.user_name}</div>
          </>
        );
      },
    },

    {
      name: "Store Id",
      center: true,
      selector: (row) => row.store_id,
    },
    {
      name: "Store Name",
      center: true,
      selector: (row) => row.store_name,
      //give width: "100px",
      cell: (row) => {
        return (
          <>
            <div>{row.store_name}</div>
          </>
        );
      },
    },

    {
      name: "Register Id",
      center: true,
      selector: (row) => row.register_id,
    },

    {
      name: "Country",
      center: true,
      selector: (row) => row.country,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="d-flex">
            <div style={{cursor:'pointer'}}>
                    <AdduserModal/>
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                     onClick={()=>DeleteUserByid(row.user_id)}
                    />
                  </div>
              <div>
                <MdEdit
                  size={22}
                  color="green"
                  className="mouse-pointer"
                  onClick={() => {
                    setSelectedrow(row)
                    setModalShow((state) => !state);
                  }}
                />
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        {" "}
        <Container>
        <Row className='m-1 justify-content-center'>
          <Col xs={12} sm={8}
          >
            <InputGroup className="" 
            >
              <FormControl
                placeholder="Search User Id And Store Id"
                aria-label="Search"
                aria-describedby="basic-addon2"
                onChange={(e)=>setSearchKey(e.target.value)  }
              />
              <Button 
              style={{width: '77px',
  height: '40px',
  flexShrink: 0,
  background: '#565ADD',}}><FaSearch /> </Button>

            </InputGroup>
          </Col>
        </Row>
          <DataTable
            columns={columns}
            responsive={true}
            fixedHeader={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={count}
            progressPending={loading}
            onChangePage={(page) => handlePageChange(page)}
          />
        </Container>
      </div>
      <GetUserModal
      userData ={selectedrow}
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
        GetUser={GetUser}
      />
    </>
  );
}

export default UserMaster;
