import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DataService from "../../services/requestApi";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
const CouponPermotionRule = () => {
  const [show, setShow] = useState(false);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [store_Id, setStoreId] = useState("");
  const handleClose = () => setShow(false);
  const [data, setdata] = useState("");
  const [newData, setNewdata] = useState("");
  const handleShow = (item) => {
    setShow(true)
    setNewdata(item)
    setStoreId(item.storeId)
  };
  const GetPermotion = async () => {
    try {
      const response = await DataService.GetAllpermotionRule(saasId);
      setdata(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetPermotion();
  }, []);

  const handleonchange = (e) => {
    // console.log(e.target.name, e.target.value);
    setNewdata({ ...newData, [e.target.name]: e.target.value });
  };

  const Updatedata = async () => {
    try {
      const ApiData = {
        effectiveFrom: newData.effectiveFrom,
        status: newData.status,
        noOfDays: newData.noOfDays,
        amountRange1: newData.amountRange1,
        amountRange2: newData.amountRange2,
        discountAmount: newData.discountAmount,
      };
      if (!store_Id) {
        Swal.fire({ title: "Please select Store", icon: "info", timer: 2000 });
        return;
      }
      const Response = await DataService.UpdatePermotionRule(store_Id, ApiData);
      if (Response.data.status) {
        console.log(Response.data.data);
        handleClose();
        GetPermotion();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Store Id</th>
            <th>status</th>
            <th>Discount</th>
            <th>Expiration Date</th>
            <th>AmountRange1</th>
            <th>AmountRange2</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((el) => {
              return (
                <tr>
                  <td>{el.storeId}</td>
                  <td>{el.status}</td>
                  <td>{el.discountAmount}</td>
                  <td>{el.expirationDate}</td>
                  <td>{el.amountRange1}</td>
                  <td>{el.amountRange2}</td>
                  <td>
                    <Button onClick={()=>{handleShow(el)}}>Edit</Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Promotion Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            size="small"
            type="number"
            onChange={(e) => setStoreId(e.target.value)}
            className="form-control my-2"
            name="StoreId"
            value={store_Id}
            label="Store ID"
            required
            rows={1}
          />
          <TextField
            size="small"
            type="number"
            onChange={handleonchange}
            className="form-control my-2"
            name="amountRange1"
            value={newData.amountRange1}
            label="Min Range"
            required
            rows={1}
          />
          <TextField
            size="small"
            type="number"
            onChange={handleonchange}
            value={newData.amountRange2}
            className="form-control my-2"
            name="amountRange2"
            label="Max Range"
            required
            rows={1}
          />
          <FormControl fullWidth>
            <InputLabel id="isdcode-label" style={{ width: "fit-content" }}>
              Status
            </InputLabel>
            <Select
              className="mb-3"
              label="Select Store"
              labelId="isdcode-label"
              id="discount_type"
              name="status"
              value={newData?.status}
              onChange={handleonchange}
              required
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            type="date"
            onChange={handleonchange}
            value={newData.effectiveFrom}
            className="form-control my-2"
            name="effectiveFrom"
            label="Max Range"
            required
            rows={1}
          />
          <TextField
            size="small"
            type="number"
            onChange={handleonchange}
            value={newData.discountAmount}
            className="form-control my-2"
            name="discountAmount"
            label="Discount Amount"
            required
            rows={1}
          />
          <TextField
            size="small"
            type="number"
            onChange={handleonchange}
            value={newData.noOfDays}
            className="form-control my-2"
            name="noOfDays"
            label="Number Of Day For running Permotion"
            required
            rows={1}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={Updatedata}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CouponPermotionRule;
