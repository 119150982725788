import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { loginDetails } from "./Redux/Reducer";
import { BASEURL } from "./services/http-common";
import DataService from "./services/requestApi";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { RxCross2 } from "react-icons/rx";
export const Tillopen = () => {
  const focusRef = useRef(null);
  const Ref = useRef();
  const { storeId, saasId, registerId, userName ,tillId} = JSON.parse(
    localStorage.getItem("User_data")
  );
  const [IsSubmiting, setIsSubmiting]=useState(false)
   
  const [tillData, setTillData] = useState({
    till_id: registerId,
    float_amount: "",
    cashier_id: "",
  });
  const [MultiFor, setMultiFor] =  useState({
    iqd_250: "",
    iqd_500: "",
    iqd_1000: "",
    iqd_5000: "",
    iqd_10000: "",
    iqd_25000: "",
    iqd_50000:"",
  })
  const [data, setData] = useState({
    till_id:tillData.till_id,
    iqd_250: "",
    iqd_500: "",
    iqd_1000: "",
    iqd_5000: "",
    iqd_10000: "",
    iqd_25000: "",
    iqd_50000:"",
    saad_id: saasId,
    store_id: storeId,
  });
  const sum = Object.values(MultiFor).reduce((acc, curr) => acc + parseInt(curr || 0), 0);
  useEffect(() => {
    console.log("total denomtion", sum)
  }, [sum])
 
  const { till_id, float_amount, cashier_id } = tillData;
  const navigate = useNavigate();
  const logindata = useSelector((state) => state.Reducer.login[0]);
  const changeHandler = (e) => {
    setTillData({ ...tillData, [e.target.name]: e.target.value });
  };
  const subHandler = async (e) => {
    e.preventDefault();
    setIsSubmiting(true)
    const Data = {
      float_amount: sum,
      register_id: registerId,
      saas_id: saasId,
      store_id: storeId,
      till_id: tillData.till_id,
      cashier_id:userName
    };
    try {
      // if (!till_id || !float_amount) return Swal.fire({title:"All fields are required", icon:"error", timer:1500});
      const response = await axios.post(
        `${BASEURL.ENDPOINT_URL}/till/till-open`,
        Data
      );
      if (response?.data?.status) {
        const SaveDenomntion = await DataService.SaveFloatAmount(data)
        setIsSubmiting(false)
        navigate("/");
        Swal.fire({
          title: "Till Open successfully",
          icon: "success",
          timer: 2000,
        });
      }
    } catch (error) {
      setIsSubmiting(false)
      console.log("Error submitting form:", error);
      navigate("/");
      if(error.response.data.message == "Another till is already open for the given registerId"){
        Swal.fire({
          title:"Till Already Open",
          icon:"success",
          timer:2000,
        })
      }else{
        Swal.fire({
          title: error.response.data.message,
          icon: "error",
        });
      }
    }
  };
  const getFocus = () => {
    focusRef.current.focus();
  };

  const handleClearInputFields = (event) => {
    if (event.ctrlKey && event.key === "z") {
      setTillData({
        till_id: "",
        float_amount: "",
      });
      setTimeout(() => {
        getFocus();
      }, 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        navigate("/tilloptions");
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => {
      getFocus();
    }, 500);
  }, []);

  
  const handelonChange = (e) => {
    e.preventDefault();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // const total = Object.values(data).reduce((acc, curr) => {
  //   const num = parseInt(curr);
  //   return isNaN(num) ? acc : acc + num;
  // }, 0);
  return (
    <div>
      <Container>
        <Navbar className="justify-content-Between">
          <Navbar.Collapse className="justify-content-center">
            <Navbar.Brand>Photon Softwares</Navbar.Brand>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <hr />
      <Container>
        <div className="my-5">
          <Link to={"/tilloptions"} style={{ color: "black" }}>
            <AiOutlineArrowLeft
              style={{ width: "50px", height: "20px" }}
              className="float-start"
            />
          </Link>
          <h5>Till Open</h5>
        </div>
        <Row>
          <Col>
            {/* xs={12} sm={10} md={8} lg={6} */}
            <div>
              <Form onSubmit={subHandler}>
                <h6 className="my-4">Enter Till Id</h6>
                <Form.Control
  value={till_id}
  disabled 
/>
                <h6 className="my-4">Enter Total Amount of Float</h6>
                   {/* <Form.Control
                  placeholder="Enter amount"
                  ref={focusRef}
                  value={float_amount}
                  name="float_amount"
                  onChange={changeHandler}
                  onKeyDown={handleClearInputFields}
                ></Form.Control> */}
                <Row>
          <Col >
            <Form>
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 250</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          ref={focusRef}
                          autoFocus
                          name="iqd_250"
                          value={data?.iqd_250}
                          onChange={(e)=>{handelonChange(e);
                          setMultiFor({
                            ...MultiFor,
                            [e.target.name]: e.target.value * 250,
                          })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_250 * 250}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 500</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_500"
                          value={data?.iqd_500}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 500,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_500 * 500}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 1000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_1000"
                          value={data?.iqd_1000}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 1000,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_1000 * 1000}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 5000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_5000"
                          value={data?.iqd_5000}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 5000,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_5000 * 5000}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 10000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_10000"
                          value={data?.iqd_10000}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 10000,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_10000 * 10000}</span>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 25000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_25000"
                          value={data?.iqd_25000}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 25000,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_25000 * 25000}</span>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">INR 50000</p>
                    </Col>
                    <Col>
                      <span className="float-end py-2">
                        <RxCross2 />
                      </span>
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        <Form.Control
                          name="iqd_50000"
                          value={data?.iqd_50000}
                          onChange={(e)=>{handelonChange(e);
                            setMultiFor({
                              ...MultiFor,
                              [e.target.name]: e.target.value * 50000,
                            })}}
                        ></Form.Control>
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>={data?.iqd_50000 * 50000}</span>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
            <div className="py-1">
                <div
                  style={{
                    height: "45px",
                    width: "380px",
                    background: "#f2f0f0",
                  }}
                >
                  <Row>
                    <Col>
                      {" "}
                      <p className="py-2 mx-2 text-nowrap">Total</p>
                    </Col>
                    <Col>
                      {/* <span className="float-end py-2">
                        <RxCross2 />
                      </span> */}
                    </Col>
                    <Col>
                      <div className="py-1 float-end">
                        
                      </div>
                    </Col>
                    <Col className="d-flex fw-bold justify-content-center mt-2">
                        <span>{sum}</span>
                    </Col>
                  </Row>
                </div>
              </div>
          </Col>
        </Row>
             

                <div className="my-5 ml-5">
                  <center>
                    <div className="my-5">
                    <Button
                        type="submit"
                        className="text-White  border  mx-2"
                        style={{ width: "250px", background: "#2E45AF" }}
                        disabled={IsSubmiting}
                      >
                        <span
                          className="float-start my-2"
                          style={{ background: "#2E45AF" }}
                        >
                          Next
                        </span>{" "}
                      </Button>
                      <Button
                        className="text-White  border  mx-2"
                        variant="none"
                        style={{ width: "250px" }}
                      >
                        <span className="float-start my-2">Cancel</span>{" "}
                        <Button
                          className=" border float-end text-dark"
                          variant="none"
                        >
                          Esc
                        </Button>
                      </Button>
                      
                    </div>
                  </center>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
