import React, { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { BiSearchAlt2 } from "react-icons/bi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataService from "../services/requestApi";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import OpenCashDrawermoadal from "./OpenCashDrawermoadal";

function CheckPriceModal(props) {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [itemId, setItemId] = useState("");
  const [data, setdata] = useState("");
  const [styleCode, setStyleCode] = useState("");
  const [styleData, setStyleData] = useState("");
  const focus = useRef(null); // Initialize the ref with null

  const CheckPrice = async (e) => {
    e.preventDefault();
    try {
      if(itemId){
        const response = await DataService.ItemPriceForStores(itemId, saasId);
        console.log("this price check", response.data.data);
        if (response.data.status) {
          setdata(response.data.data);
          setItemId("");
        }
      }
    } catch (error) {
      console.log("this catch error", error);
    }
  };

  const LookupByStyleCode = async (code) => {
    try {
      const response = await DataService.GetItemBYStyleCode(storeId, saasId,code);
      console.log("Lookup by style code", response.data);
      if (response.data.status) {
        setStyleData(response.data.data);
        setStyleCode("");
        console.log("setyled data", styleData);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (focus.current) {
      focus.current.focus();
    }
  }, []);
  // <----------------------------------manger check --------------------------->
  const [Userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [isManger, setIsManager] = useState(false);
  const CheckManger = async () => {
    try {
      const response = await DataService.CheckManger(Userid, password);
      console.log("check manger", response);
      if (response.data.status) {
        if (response.data.data.user_type == "MANAGER") {
          setIsManager(true);
        }
      } else {
        Swal.fire({
          title: "You are Not Allow",
          icon: "error",
        
        });
      }
    } catch (error) {
      console.log("Catch Error", error);
    }
  };

  return (
    <Modal size="lg" {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
      <div className='d-flex'>
                               < AiOutlineArrowLeft   onClick={() => props.onHide()} className='fs-4' /><h4 className='mx-3'>Item Lookup</h4>
                             </div>
        <Tabs
          defaultActiveKey="priceCheck"
          id="justify-tab-example"
          className="mt-3"
        >
          <Tab eventKey="priceCheck" title="Price Check">
            <Row>
              <Col>
                {/* <Row className="my-4">
                  <Col className="d-flex justify-content-around">
                    <span>Item Name: {data?.item_name} </span>
                    <span>Price: {data && `IQD: ${data.price}`} </span>
                  </Col>
                </Row> */}
                <Row>
                  <Form onSubmit={(e) => CheckPrice(e)}>
                    <Col className="d-flex m-3 ">
                      <FormControl
                        type="text"
                        autoFocus
                        value={itemId}
                        onChange={(e) => setItemId(e.target.value)}
                        placeholder="Enter item barcode"
                        className="mr-2"
                        aria-label="Search"
                      />

                      <Button
                        type="button"
                        onClick={(e) => {
                          CheckPrice(e);
                        }}
                        variant="primary"
                      >
                        <BiSearchAlt2
                          style={{ height: "2rem", width: "2rem" }}
                        />
                      </Button>
                    </Col>
                  </Form>
                </Row>
                <Row>
             {data&&<div className="container mx-auto py-10">
      <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
        <thead>
          <tr style={{ borderBottom: '2px solid #ddd' }}>
            <th style={{ padding: '12px' }}>Store ID</th>
            <th style={{ padding: '12px' }}>Price 1</th>
            <th style={{ padding: '12px' }}>Price 2</th>
            <th style={{ padding: '12px' }}>Price 3</th>
          </tr>
        </thead>
        <tbody>
          {data&&data?.map((item) => (
            <tr key={item.store_Id} style={{ borderBottom: '1px solid #ddd' }}>
              <td style={{ padding: '12px' }}>{item.store_Id}</td>
              <td style={{ padding: '12px' }}>{item.price1 !== null ? item.price1?.toFixed(2) : 'N/A'}</td>
              <td style={{ padding: '12px' }}>{item.price2 !== null ? item.price2?.toFixed(2) : 'N/A'}</td>
              <td style={{ padding: '12px' }}>{item.price3 !== null ? item.price3?.toFixed(2) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>}
                </Row>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="OpenDrawer" title="Open Cash Drawer">
          {isManger ?
          <OpenCashDrawermoadal setIsManager={setIsManager} CloseModal={props.onHide} />:
          <Form className="d-flex justify-content-center">
              <Row className="d-flex flex-column w-50 gap-3" style={{ margin: "40px 0px 0px -10px" }}>
                <Col className="align-items-center d-flex justify-content-center">
                  <Form.Control
                    type="text"
                    autoFocus={true}
                    onChange={(e) => setUserid(e.target.value)}
                    style={{ width: "88%" }}
                    placeholder="Enter User Id"
                  ></Form.Control>
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                  <Form.Control
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: "88%" }}
                    placeholder="Enter Password"
                  ></Form.Control>
                </Col>
                <Col className="align-items-center d-flex justify-content-center">
                <Button type="button" onClick={CheckManger} className="btn btn-primary btn-block">Check Manger</Button>
                </Col>
              </Row>
              
              {/* <Form.Label>Discount(%)</Form.Label>
                <Form.Control style={{width:"88%"}} placeholder="0"></Form.Control> */}
            </Form>}
          </Tab>
          {/* <Tab eventKey="lookup" title="Lookup">
          <Row>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      LookupByStyleCode(styleCode);
                    }}
                  >
                    <Col className="d-flex mt-3">
                      <FormControl
                        type="text"
                        autoFocus
                        value={styleCode}
                        onChange={(e) => setStyleCode(e.target.value)}
                        placeholder="Enter Item code"
                        className="mr-2"
                        aria-label="Search"
                      />

                      <Button type="submit" variant="primary">
                        <BiSearchAlt2
                          style={{ height: "2rem", width: "2rem" }}
                        />
                      </Button>
                    </Col>
                  </Form>
                </Row>
            <Row>
              <Col style={{
                overflowY: "auto",
                height: "500px",
                position: "relative"
              }}>
              { styleData && styleData.map((product)=>{return(<div className="container my-4">
  <div className="card w-100 mx-auto" style={{ maxWidth: '600px' }}>
    <div className="card-header">
      <h5 className="card-title">{product.item_name}</h5>
      <div className="d-flex align-items-center ">
        <span className="badge bg-outline me-2 text-black">
          {product.status}
        </span>
        <span className="badge bg-outline-secondary text-black">
          ID: {product.item_id}
        </span>
      </div>
    </div>
    <div className="card-body">
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Description</h6>
          <p>{product.description}</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">Category</h6>
          <p>{product.category}</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Price</h6>
          <p className="fw-bold">${(product.price / 100)?.toFixed(2)}</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">Actual Price</h6>
          <p className="fw-bold">${(product.actual_price / 100)?.toFixed(2)}</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Barcode</h6>
          <p className="font-monospace">{product.barcode}</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">Item Code</h6>
          <p className="font-monospace">{product.item_code}</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-4">
          <h6 className="text-muted">Department</h6>
          <p>{product.dept}</p>
        </div>
        <div className="col-4">
          <h6 className="text-muted">Item Class</h6>
          <p>{product.item_class}</p>
        </div>
        <div className="col-4">
          <h6 className="text-muted">Sub Class</h6>
          <p>{product.sub_class}</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Quantity</h6>
          <p>{product.product_qty}</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">Discount</h6>
          <p>{product.discount}%</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Tax</h6>
          <p>${(product.tax / 100)?.toFixed(2)} ({product.tax_percent}%)</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">HSN Code</h6>
          <p>{product.hsn_code}</p>
        </div>
      </div>
      <hr />
      <div className="row g-3">
        <div className="col-6">
          <h6 className="text-muted">Store ID</h6>
          <p>{product.store_id}</p>
        </div>
        <div className="col-6">
          <h6 className="text-muted">SaaS ID</h6>
          <p>{product.saas_id}</p>
        </div>
      </div>
    </div>
  </div>
</div>)})}
                {/* {styleData && styleData.map((el)=>{return(<Row className="my-4">
                  <Col className="d-flex justify-content-around">
                    <span>Style Name: {el?.style_name} </span>

                    <span>
                      {styleData && `Item Code: ${el?.item_code}`}{" "}
                    </span>
                  </Col>
                </Row>)})} 
                
              </Col>
            </Row>
          </Tab> */}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default CheckPriceModal;
