import http, { BASEURL } from "./http-common";

class DataService {
  // auth api's
  GetItemBYStyleCode(storeid,saasId,style) {
    return http.get(`/search/item-lookup/${storeid}/${saasId}/${style}`);
  }
  TransactionLog(data) {
    return http.post("/transaction-log/save-transaction-log", data);
  }
  UpdateUserMaster(data, userId) {
    return http.put(`/register/update-user/${userId}`, data);
  }
  UserMaster(data) {
    return http.post("/user-master/add-user", data);
  }
  StoreMaster(data) {
    return http.post("/store-master/add-store-master", data);
  }
  UpdateStoreMaster(Userid, formData) {
    return http.put(`store-master/update-store/${Userid} `, formData);
  }
  TenderReport(data) {
    return http.post("/reconciliation/get-total-amount", data);
  }
  AddNewItem(data) {
    return http.post(`/item/add-item`, data);
  }
  GetItemById(storeId, saasId, itemId) {
    return http.get(`/search/item-by-itemId/${storeId}/${saasId}/${itemId}`);
  }
  GetREGlist(saasId, storeId) {
    return http.get(`/till/get-list-regid/${saasId}/${storeId}`);
  }
  GetLoyalityIssuesReport(saasId, storeId, businessDate) {
    return http.get(`/customer/get-customers/details/${saasId}/${storeId}/${businessDate}`);
  }
  GetItemCodeName(storeId,saasId,ItemCode) {
    return http.get(`/item/get_item_sales_history/${storeId}/${saasId}/${ItemCode}`);
  }
  GetStaffDiscount(saasId, storeId, bussinesDate) {
    return http.get(
      `/report/get-staff-discount-report/${saasId}/${storeId}/${bussinesDate}`
    );
  }
  GetStaffPerformanceDiscount(storeId, saasId,startDate, bussinesDate) {
    return http.get(
      `/report/get-staff-performance-report/${storeId}/${saasId}/${startDate}/${bussinesDate}`
    );
  }
  GetLoylityCardRedemption(saasId, storeId, bussinesDate) {
    return http.get(
      `/report/get-loyalty-redemption-report/${saasId}/${storeId}/${bussinesDate}`
    );
  }
  UpdateREGlist(Userid, RegId) {
    return http.put(`/register/update-regid/${Userid}/${RegId} `);
  }
  saveTransction(data) {
    return http.post("/transaction/save-transaction", data);
  }
  Startofthedat(data) {
    return http.post("/start-of-the-day", data);
  }
  TillRegisterOpen(data) {
    return http.post(`/till/register-open`, data);
  }
  TillRegisterClose(saasId, storeId, registerId) {
    return http.put(
      `/till/register-close/CLOSE/${saasId}/${storeId}/${registerId}`
    );
  }
  EndOftheDay(storeid, saasId, date) {
    return http.get(`/till/end-of-the-day/${storeid}/${saasId}/${date}`);
  }
  CheckManger(Userid, password) {
    return http.get(`/user-master/get-manager-override/${Userid}/${password}`);
  }
  EndFotheday(date, storeId, saasId) {
    return http.put(`/till/end-of-the-day-close/${storeId}/${saasId}/${date}`);
  }
  TransctionPrice(Data) {
    return http.post(`/user-master/add-Override-transaction-price`, Data);
  }
  TillReconcile(data) {
    return http.post(`/concile/save-till-reconcile`, data);
  }
  SaveForeigncurrency(data) {
    return http.post(`/concile/save-foreign-currency`, data);
  }
  SaveCurrency(data) {
    return http.post(`/currency/saveCurrency`, data);
  }
  GetCustomerForLink(saasId, storeId) {
    return http.get(`/user-master/get-user-details/${saasId}/${storeId}`);
  }
  ReconcileRepostPrint(date, saasId, storeId, till_id) {
    return http.get(
      `/till-summary/get-till-phyical-count/${date}/${saasId}/${storeId}/${till_id}`
    );
  }
  GetTillSales(date, saasId, storeId, till_id) {
    return http.get(
      `/till-summary/get-till-summary/${date}/${saasId}/${storeId}/${till_id}`
    );
  }

  AverageSales(saasId, storeId, date) {
    return http.get(`/report/get-average-sales/${storeId}/${saasId}/${date}`);
  }
  GetReturnReceipt(invoiceNo, saasId, storeId) {
    return http.get(
      `/return-receipt/get-receipt-credit-note/${invoiceNo}/${storeId}/${saasId}`
    );
  }
  GetSalesmane(saasId, storeId, salsemanId) {
    return http.get(
      `/sales-man/get-sales-man/${saasId}/${storeId}/${salsemanId}`
    );
  }
  GetReturnTransection(data) {
    return http.post(`/return-receipt/add-credit-Note`, data);
  }

  //Dash Bord Apis Start --------------->

  //Dash Bord Apis Start --------------->

  HourlySales(saasId, storeId, businessDate) {
    return http.get(
      `/report/hourly-sales-report/${storeId}/${saasId}/${businessDate}`
    );
  }
  ConceptDeptReport(saasId, storeId, date) {
    return http.get(`/report/sum_item_net/${saasId}/${storeId}/${date}`);
  }
  getSalesSummary(saasId,storeId, startDate, endDate) {
    return http.get(
      `/dashboard/get-invoices-detatils/${saasId}/${storeId}/${startDate}/${endDate}`
    );
  }
  getDailySales(startDate, storeId, saasId) {
    return http.get(`/tax/get-sales-report/${startDate}/${storeId}/${saasId}`);
  }
  AverageHourlySales(saasId, storeId, businessDate) {
    return http.get(
      `/report/get-hourly-average-sales-report/${storeId}/${saasId}/${businessDate}`
    );
  }
  GetTillSummary(saasId, storeId, businessDate, Reg, tillid) {
    return http.get(
      `/concile/get-till-summary-report/${saasId}/${storeId}/${businessDate}/${Reg}/${tillid}`
    );
  }
  GetStoreSummary(saasId, storeId, businessDate) {
    return http.get(
      `/concile/get-store-summary/${saasId}/${storeId}/${businessDate}`
    );
  }
  GetItemMaster(saasId, storeId, page) {
    return http.get(`/item/get-item-list/${saasId}/${storeId}/${page}`);
  }
  GetSubClass(page, size) {
    return http.get(`/SubClassMaster/getall-SubClassMaster/${page}/${size}`);
  }
  GetConceptAll() {
    return http.get(`/concept-master/view-concept`);
  }
  DeptMaterGet() {
    return http.get(`/deptMaster/getall-deptMaster`);
  }
  GetAllSalesMan(page) {
    return http.get(`/sales-man/get-all-salesman/${page}`);
  }
  GetAllUserMaster(page, saasId, storeId) {
    return http.get(`/user-master/get-users/${page}/${saasId}`);
  }
  GetAllStoreMaster(saasId, storeId) {
    return http.get(`/store-master/get_store_detail/${saasId}`);
  }
  GetAllCurrencyMaster(data) {
    return http.get(`/exchange/get-all-exchange-rate`, data);
  }
  GetAllLogs(saasId, storeId, business_date) {
    return http.get(
      `/transaction-log/get-all-logdetail/${saasId}/${storeId}/${business_date}`
    );
  }
  GetViewLogs(userId, Reg, logId) {
    return http.get(
      `/transaction-log/get-transaction-log1/${userId}/${Reg}/${logId}`
    );
  }
  deleteConcept(conc_id) {
    return http.delete(`/concept-master/delete-concept/${conc_id}`);
  }
  deleteSubClass(sub_class_code) {
    return http.delete(
      `/SubClassMaster/delete-SubClassMaster/${sub_class_code}`
    );
  }
  UpdateConcepte(conc_id, data) {
    return http.put(`/concept-master/update-concept/${conc_id}`, data);
  }
  UpdateSubClass(sub_class_code, data) {
    return http.put(
      `/SubClassMaster/update-SubClassMaster/${sub_class_code}`,
      data
    );
  }
  AddSubClass(data) {
    return http.post(`/SubClassMaster/add-SubClassMaster`, data);
  }

  AddNewConcepte(data) {
    return http.post(`/concept-master/create-concept`, data);
  }
  SalesManUpdate(sales_man_id, data) {
    return http.put(`/sales-man/update-sales-man/${sales_man_id}`, data);
  }
  deleteSalesMan(sales_man_id) {
    return http.delete(`/sales-man/delete-sales-man/${sales_man_id}`);
  }
  GetAllCustomer(page) {
    return http.get(`/customer/get-all-customers/${page}`);
  }
  deleteCustomer(customer_id) {
    return http.delete(`/customer/delete-customers/${customer_id}`);
  }
  GetAllClassMaster(page, size) {
    return http.get(`/class/getall-classMaster/${page}/${size}`);
  }
  UpdateDeptMaster(sub_class_code, data) {
    return http.put(`/deptMaster/update-dept/${sub_class_code}`, data);
  }
  UpdateClassMaster(id, data) {
    return http.put(`/class/update-class/${id}`, data);
  }
  UpdateDepart(dept_id, data) {
    return http.put(`/deptMaster/update-dept/${dept_id}`, data);
  }
  CustomerUpdate(customer_id, data) {
    return http.put(`/customer/update/${customer_id}`, data);
  }
  AddClassMaster(data) {
    return http.post(`/class/add-class`, data);
  }
  AddDeptMaster(data) {
    return http.post(`/deptMaster/add-dept`, data);
  }
  AddSalesMan(data) {
    return http.post(`/sales-man/add-sales-man`, data);
  }
  AddCutomer(data) {
    return http.post(`/customer/create`, data);
  }
  DeleteClass(id) {
    return http.delete(`/class/delete-class/${id}`);
  }
  DeleteDepartment(id) {
    return http.delete(`/deptMaster/delete-sales-man/${id}`);
  }

  CreditNoteGet(Id) {
    return http.get(`/return-receipt/get-credit-note-Id/${Id}`);
  }

  UpdateCreditNote(Id, Amount,invoiceNo) {
    return http.put(`/return-receipt/view-credit-Note-id/${Id}/${Amount}/${invoiceNo}`);
  }

  GetHoldTransction(InvoiceNo) {
    return http.get(`/transaction/unhold-transaction/${InvoiceNo}`);
  }
  // Invantory DashBord Api's ------------------------->
  SalesOverView(saasId, storeId) {
    return http.get(
      `inventory-master/inventory-dashboard/${saasId}/${storeId}`
    );
  }
  InventorySummary(saasId, storeId) {
    return http.get(`inventory-master/closing-stock/${saasId}/${storeId}`);
  }
  TotalCustomer(saasId, storeId) {
    return http.get(`inventory-master/total-customer/${saasId}/${storeId}`);
  }
  ClosingQty(saasId, storeId) {
    return http.get(
      `inventory-master/inventory-less-closing-quantity/${saasId}/${storeId}`
    );
  }
  TotalNoofItem(saasId, storeId) {
    return http.get(`inventory-master/no-of-items/${saasId}/${storeId}`);
  }
  ReturnTransectionPdf(data) {
    return http.post(`/transaction/return-transaction`, data);
  }

  HoldCartItem(data) {
    return http.post(`/transaction/hold-transaction`, data);
  }
  AddSupplier(data) {
    return http.post("/supplier/create", data);
  }

  GetParties(store_id, saas_id, name) {
    return http.get(
      `/customer/search-customer/${store_id}/${saas_id}/${name} `
    );
  }


  //loyality api intigrate Start here ------------------------>
  LoyalityIssues(CustId, LoyaltyId, data) {
    return http.post(
      `${BASEURL.LOYALTY_URL}/loyalty/issue/${CustId}/${LoyaltyId}`,
      data
    );
  }
  RedeemLoyalti(LoyaltyId,data){
    return http.post(
      `${BASEURL.LOYALTY_URL}/loyalty/redeem-points/${LoyaltyId}`,
      data
    )
  }
  GetCoupnCode(MobailNum,storeId){
    return http.get(`${BASEURL.LOYALTY_URL}/coupon/get-allcoupon-by-mobileno/${MobailNum}/${storeId}`)
  }
  GetOneCoupen(Code,store_id,totalamount){
    return http.get(`${BASEURL.LOYALTY_URL}/coupon/get-coupon/${Code}/${store_id}/${totalamount}`)
  }
   
  UpdateCoupn(Code){
    return http.put(`${BASEURL.LOYALTY_URL}/coupon/update-coupon-status/${Code}`)
  }
  CreatCoupun(data){
    return http.post(`${BASEURL.LOYALTY_URL}/coupon/create-coupon`,data)
  }
  GetLoyaltyCustomer(number){
    return http.post(`${BASEURL.LOYALTY_URL}/loyalty/customer-details`,number)
  }
  GetPermotionRule(saasId,storeId){
    return http.get(`${BASEURL.LOYALTY_URL}/coupon/get-promotion-rule/${saasId}/${storeId}`)
  }
  GetAllpermotionRule(saasId){
    return http.get(`${BASEURL.LOYALTY_URL}/coupon/get-promotion-rule-saasId/${saasId}`)
  }
  
  CreatCouponByThreshold(total,data){
    return http.post(`${BASEURL.LOYALTY_URL}/coupon/create-coupon-bypromotion-rule/${total}`,data)
  }

  UpdatePermotionRule(storeId,data){
    return http.put(`${BASEURL.LOYALTY_URL}/coupon/update-promotion/${storeId}`,data)
  }
  // <-------------------------------------End loaylti api ------------------------------>


  

  GetAllHsnMaster() {
    return http.get(`/tax/get-hsn-item-list`);
  }
  GetTaxes() {
    return http.get(`/tax/get-taxes`);
  }
  CreitDebitNote(data) {
    return http.post(`/Debit/create-debit-chalan`, data);
  }

  ForiegnCurrncy(data) {
    return http.post(`/exchange/get-exchange-value`, data);
  }
  GetOpendTill(storeId,saasId,REG) {
    return http.get(`/till/get-open-till-todays/${storeId}/${saasId}/${REG}`);
  }
  GetOldData(saasId,storeId,REG,business_date) {
    return http.get(`/currency/getCurrency/${saasId}/${storeId}/${REG}/${business_date}`);
  }


  AddCurrancyMaster(id, data) {
    return http.put(`/exchange/update-currency/${id}`, data);
  }
  GetAllOpenRegisterAndtill(saasId, storeId, date) {
    return http.get(
      `/till/get-data-by-saasId-and-storeId/${saasId}/${storeId}/${date}`
    );
  }

  GetallSalesmanID(saasId, storeId) {
    return http.get(`/sales-man/get-all-salesman1/${saasId}/${storeId}`);
  }

  getExchangeRate() {
    return http.get(`/exchange/get-exchange-rate`);
  }
  SaveFloatAmount(data){
    return http.post(`/currency/saveTillReconcileFloat`,data)
  }
  DownloadItemReportCsv(date,storeId){
    return http.get(`/csv/itemreport/${date}/${storeId}`)
  }
  GetBogoDiscount(storeId,saasId,itemid,data){
    return http.put(`/bogo/item-by-bogo/${storeId}/${saasId}/${itemid}`,data)
  }
  DeleteBogoitem(barcode,cashierId){
    return http.delete(`/bogo/delete-by-barcode/${barcode}/${cashierId}`)
  }
  DeleteAllBogo(cashierId){
    return http.delete( `/bogo/clear-all-bogo-cart/${cashierId}`)
  }
  GetBogoStatus(storeId,saasId){
    return http.get(`/bogo/bogo-status/${storeId}/${saasId}`,)
  }
  GetCsvHistory(){
    return http.get(`/item/get-all-csv-uploaded`,)
  }
  GetStoreBySaasid(saasId){
    return http.get(`/store-master/get-store_master/${saasId}`)
  }
  GetSalesManBySearch(key){
    return http.get(`/sales-man/get-sales-man-byStoreOrSalesId/${key}`)
  }
  DeleteUser(id){
    return http.delete(`/user-master/delete-user/${id}`)
  }
  getInvoiceDetail(invoiceNo){
    return http.get(`/transaction/get-pdfdata/${invoiceNo}`)
  }
   
  GetUserBySearch(key){
    return http.get(`/user-master/get-userbyStoreOrUsername/${key}`)
  }
  ItemPriceForStores(key, saas_id){
    return http.get(`/search/item-currentPrice/${key}/${saas_id}`)
  }  

  ItemHistoryBycode(saas_id, store_id, date, item_code){
    return http.get(`/report/get-item-history/${saas_id}/${store_id}/${date}/${item_code}`)
  }

  DisblePermostion(Data){
    return http.post(`/promotiontime/create`,Data)
  }
  
  ApplyFlatDiscount(Data){
    return http.post(`/search/create_flat_discount`,Data)
  }
}
export default new DataService();
