import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import DataService from "../../../services/requestApi";
import Swal from "sweetalert2";

function GetUserModal(props) {
  const [countries, setCountries] = useState([]);
  const { saasId, storeId, userId } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const { show } = props;
  const [userMasterData, setUserMasterData] = useState({
    registerId: "",
    user_name: "",
    password: "",
    store_name: "",
    storeId: storeId,
    saasId: saasId,
    FMC_TOKEN: "",
    TILL_ID: 12345,
    expiration: "2024-12-22",
    city: "",
    state: "",
    country: "",
    base_currency: "",
    special_price: "",
    category_name: "",
    user_type: "",
    status: true,
    BAAR_CODE: "Baar Code123",
    CATEGORY_REQ: "",
    created_at: "2024-02-22T12:00:00",
    subscription_check: "Subs",
    promo_case_check: "Promo",
    invoice_template: 123,
  });

  //set default values for userMasterData get from props
  useEffect(() => {
    if (props.userData) {
      setUserMasterData(props.userData);
    }
  }, [props.userData]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        // console.table(data);
        const countryOptions = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (show) {
      fetchCountry();
    }
  }, [show]);

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setUserMasterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitUserMaster = async () => {
    try {
      const response = await DataService.UpdateUserMaster(
        userMasterData,
        userId
      );
      console.log(response);
      if (response.status === 200) {
        props.GetUser();
        Swal.fire({
          title: "User Master Added Successfully",
          icon: "success",
          confirmButtonText: "Done",
          timer: 3000,
        });
        props.setModalShow(false);
      }
    } catch (error) {
      console.error(error.message);

      Swal.fire({
        title: "Something Not Working ",
        icon: "error",
        confirmButtonColor: "red",
        confirmButtonText: "Retry",
      });
    }
  };

  // Stylings:--------
  const inputStyle = {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "12px",
    border: "1px solid #ddd",
    transition: "box-shadow 0.3s ease-in-out",
  };

  const ButtonStyle = {
    width: "50%",
    borderRadius: "8px",
    background: "#0067CE",
    color: "#fff",
    padding: "12px",
    cursor: "pointer",
    border: "none",
    transition: "background 0.3s ease-in-out",
  };

  inputStyle["&:hover"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  ButtonStyle[":hover"] = {
    background: "#00509E",
  };

  inputStyle["&:focus"] = {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
    outline: "none",
  };

  ButtonStyle[":focus"] = {
    outline: "none",
  };

  inputStyle["&:active"] = {
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  ButtonStyle[":active"] = {
    background: "#003366",
  };
  //------------

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>User Master</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ alignItems: "center", width: "100%", marginLeft: "1rem" }}
      >
        <Form className="container mx-5">
          <Row className="mt-4">
            <Col md={8}>
              <FormGroup>
                <Input
                  type="text"
                  required={true}
                  placeholder="Enter Register Id"
                  style={inputStyle}
                  name="registerId"
                  value={userMasterData.registerId}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Input
                  type="text"
                  required={true}
                  placeholder="Enter User Name"
                  style={inputStyle}
                  name="user_name"
                  value={userMasterData.user_name}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Enter Password"
                  style={inputStyle}
                  name="password"
                  value={userMasterData.password}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Input
                  type="text"
                  required={true}
                  placeholder="Enter Store Name"
                  style={inputStyle}
                  name="store_name"
                  value={userMasterData.store_name}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Select
                  options={countries}
                  placeholder="Select Your Country"
                  styles={inputStyle}
                  value={
                    countries.find(
                      (option) => option.value === userMasterData.country
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleInputChanges({
                      target: {
                        name: "country",
                        value: selectedOption?.value || "",
                      },
                    })
                  }
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Input
                  required={true}
                  placeholder="Select User Role"
                  style={inputStyle}
                  name="user_type"
                  value={userMasterData.user_type}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Input
                  required={true}
                  placeholder="Select Currency"
                  style={inputStyle}
                  name="base_currency"
                  value={userMasterData.base_currency}
                  onChange={handleInputChanges}
                />
              </FormGroup>
            </Col>

            <Col md={8}>
              <div className="d-flex justify-content-end">
                <Label>&nbsp;</Label>
                <Button
                  style={ButtonStyle}
                  block
                  onClick={handleSubmitUserMaster}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default GetUserModal;
